@use '../helpers/variables' as *;
@use '../helpers/fonts' as *;

.connection-container {
    .heading {
        font-size: 1rem;
        font-weight: 600;
    }

    .connect {
        border-radius: 3px;
        background-color: $white;
        border: 1px solid $border-blue-light;
        box-sizing: border-box;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        padding: 20px;
        gap: 20px;

        .connection-contant {
            display: flex;
            flex-direction: column;
            gap: 12px;
            font-weight: 500;

            .label {
                font-size: 1rem;
                color: $bright-gray;
                font-family: $sf-pro-display-semibold !important;
            }

            .description {
                font-size: 0.75rem;
                letter-spacing: 0.3px;
                line-height: 20px;
                color: $gray;
            }
        }

        .connections {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            justify-content: flex-start;
            gap: 40px;
            flex-wrap: wrap;
        }
    }
}

.connection-card {
    display: flex;
    border-radius: 3px;
    background-color: $white;
    border: 1px solid $border-blue-light;
    box-sizing: border-box;
    align-items: center;
    justify-content: flex-start;
    padding: 40px 20px;
    gap: 20px;
    cursor: pointer;
    position: relative;

    svg {
        width: 32px;
        height: 32px;
        position: relative;
    }

    &:hover {
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
    }

    .logo {
        height: 66px;
        width: 218px;
        object-fit: contain;

        @media (max-width: 390px) {
            width: 75%;
            height: 55px;
        }
    }

    .coming-soon-wrapper {
        margin: 0 !important;
        position: absolute;
        top: 10px;
        right: 10px;
        background-color: $system-danger-500-base;
        justify-content: center;
        padding: 6px;
        white-space: nowrap;
        border-radius: 3px;
        display: flex;
        flex-direction: row;
        align-items: center;

        .coming-soon {
            position: relative;
            line-height: 120%;
            display: inline-block;
            min-width: 85px;
            font-size: 0.75rem;
            color: $white;
        }
    }
}

.inactive {
    cursor: default;

    &:hover {
        box-shadow: none;
    }
}

.step-modal {
    display: flex;
    flex-direction: column;
    gap: 12px;

    .step-content {
        display: flex;
        gap: 12px;
        flex-direction: column;

        .step-header {
            display: flex;
            gap: 30px;
            align-items: center;
            text-wrap: nowrap;
            white-space: nowrap;

            .label {
                font-size: 1.875rem;
                line-height: 120%;
                color: $bright-gray;
            }

            .ant-progress-inner {
                background-color: $btn-bg-blue-light;
            }

            .ant-progress .ant-progress-bg {
                height: 8px !important;
            }

            .ant-progress .ant-progress-bg {
                background-color: $sky !important;
            }
        }

    }

    .step-info {
        font-size: 0.75rem;
        letter-spacing: 0.3px;
        line-height: 1.25rem;
        font-weight: 500;
        color: $meteorite-gray;
    }

    .action {
        margin-top: 24px;
        display: flex;
        gap: 24px;
        flex-direction: column;

        .terms {
            font-size: 0.75rem;
            line-height: 140%;
            color: $label-color-green;
        }


    }

    .step2-action {
        margin-top: 18px;
        display: flex;
        gap: 71px;
        flex-direction: column;

        .input-container {
            width: 50%;
            display: flex;
            flex-direction: column;
            gap: 4px;

            .input-box {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: flex-start;
                padding: 5px 0;
                gap: 6.1px;
                border-bottom: 0.5px solid $border-blue-light;

                input {
                    border: none;
                    width: 100%;
                    border: 0;
                    outline: 0;
                    padding: 0 10px;
                    font-size: 0.813rem;
                }
            }

            .input-footer {
                align-self: stretch;
                display: flex;
                flex-direction: row;
                align-items: flex-start;
                justify-content: space-between;

                .counter {
                    font-size: 0.5rem;
                    letter-spacing: 0.5px;
                    line-height: 120%;
                    text-transform: uppercase;
                    font-weight: 500;
                    color: $natural-blue;
                    opacity: 1;
                }

                .inp-error{
                    color: $system-danger-500-base;
                    font-size: 0.625rem;
                }
            }
        }
    }

    .footer-btn {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 20px;
        font-weight: 500;

        .connect {
            border-radius: 2px;
            background-color: $sky;
            border: 1px solid $btn-bg-blue-light;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 7px 9px;
            mix-blend-mode: normal;
            font-size: 0.75rem;
            color: $white;
            width: 125px;
            flex-direction: row;
            gap: 5px;

            svg {
                height: 8px;
                width: auto;
            }
        }

        .cancel {
            border-radius: 2px;
            background-color: $btn-bg-blue-light;
            border: 1px solid $btn-bg-blue-light;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 7px 9px;
            mix-blend-mode: normal;
            font-size: 0.75rem;
            color: $natural-blue;
            width: 96px;
        }
    }
}