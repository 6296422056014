$grid-breakpoints: (
  xs: 0,
  sm: 577px,
  md: 769px,
  lg: 993px,
  xl: 1201px,
  xxl: 1441px
);

$box-shadow-bottom: 0px 2px 4px rgba(40, 41, 61, 0.04), 0px 8px 16px -2px rgba(96, 97, 112, 0.16);
$box-shadow-right: 6px 0px 19px -4px rgba(40, 41, 61, 0.21), 1px 0px 6px rgba(96, 97, 112, 0.1);
$box-shadow-retangle: 0px 6px 7px rgba(40, 41, 61, 0.03), 0px 5px 5px -3px rgba(96, 97, 112, 0.02);
$black-opacity-6: rgba(0, 0, 0, 0.6);
$dark-blue-opacity-7: rgba(11, 27, 60, 0.7);
$transparent-black-2: rgba(0, 0, 0, 0.2);
$transparent-black-1: rgba(0, 0, 0, 0.1);
$transparent-smoky-black: rgba(11, 11, 11, 0.6);

$header-avatar-border: #5287CD;
$blue: #F6FAFF;
$black: #000000;
$white: #ffffff;
$primary: #01459D;
$primary-dark: #2B75D6;
$natural-blue: #5D6F88;
$border-blue: #97C3FF;
$scroll-bar: #7CB0F4;
$scroll-bar-bg: #DBE2ED;
$gray-bg: #F1F4F9;
$blue-focus-bg: #F6FAFF;
$label-color-green: #605F5F;
$active-color-blue: #085ED0;
$bright-gray: #39424E;
$fun-green: #176338;
$dark-gray: #212121;
$meteorite-gray : #3F3B3B;
$red-dark-gray : #959393;
$border-blue-light: #c7d3e6;
$gray: #666;
$status-green: #5fdd97;
$black-900: #121212;
$gray-88: #e0e0e0;
$cyan-blue:#d0e2f8;

$small-size: 12px;

$btn-bg-blue-dark: #006EFF;
$btn-bg-blue-light: #EBF4FF;
$sky: #246dcb;
$system-danger-500-base: #f46363;
$weathered: #d0e2f8;
$light-gray: #f2f7fe;
$tealish-blue: #A2ABB9;

$gray-white:#eaeaea;
$quil-gray: #d5d5d5;
$cloud-gray: #c4c4c4;
$gray-darker: #777D74;