@use '../helpers/variables' as *;
@use '../helpers/fonts' as *;

.production-board {

    height: 100%;

    &_content {
        height: calc(100% - 40px);
    }

    .header {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 57px;
        justify-content: space-between;

        .action {
            display: flex;
            align-items: center;
            gap: 20px;

            .pointer {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 40px;
                padding: 0 13px;
                border: 1px solid $natural-blue;
                border-radius: 5px;
            }
        }
        .calculation{
            gap: 24px;
            margin-left: 0;
            margin-right: auto;
        }

        @media (max-width: 900px) {
            gap: 10px;
            justify-content: space-between;

            h1 {
                order: 1;
            }
            .calculation{
                order: 3;
                width: 100%;
                flex-direction: column !important;
                gap: 0;
            }
            .action{
                order: 2;
                flex-direction: row-reverse;
                position: relative;
                right: 0;
                gap: 18px;

                button {
                    min-height: 40px;
                }
            }
            .ant-divider-vertical{
                display: none !important;
            }
            .filter_wrap{
                justify-content: flex-start;
                display: flex;
                width: 100%;
            }
        }
        @media (max-width: 460px) {
            .out-btn-primary{
                font-size: 0;
                span{
                    margin-bottom: 0 !important;
                    svg{
                    margin-right: 0 !important;
                }
                }
            }
            .filter_wrap{
                justify-content: space-between;
                display: flex;
                width: 100%;
            }
        }
    }

    .title {
        font-size: 1rem;
        line-height: 24px;
        font-family: $sf-pro-display-semibold !important;
        color: $label-color-green;
        margin-bottom: 0;
    }

    .column-title {
        color: $bright-gray;
        font-size: 0.75rem;
        font-family: $sf-pro-display-bold !important;
        line-height: 21px;

        &-wrap {
            font-family: $sf-pro-display-semibold !important;
            padding: 10px;
            width: 100%;
        }
        &-sub{
            color: $label-color-green;
            font-weight: 600;
            font-size: 0.625rem;
            letter-spacing: 0.3px;
            line-height: 16px;
        }
        &-content{
            padding-bottom: 6px;
            border-bottom: 1px solid $border-blue-light;
        }
    }

    .ant-radio-button-wrapper {
        border: none !important;
    }

    .ant-tag-checkable-checked {
        box-shadow: none !important;

        &:before {
            content: "";
            background-color: transparent !important;
            background-image: url('../../images/check-icon.png');
            position: absolute;
            top: 5px;
            left: 8px;
            height: 10px;
            width: 20px;
            background-repeat: no-repeat;
        }
    }

    .ant-radio-button-wrapper:not(:first-child)::before {
        background-color: transparent !important;
    }

    .ghost-card {
        padding: 10px;

        .skeleton-item {
            background-color: #b5b5b5;
            margin-bottom: 5px;
            opacity: 0.3;
            position: relative;
            height: 20px;

            &:last-child {
                margin-bottom: unset;
            }

            > * {
                visibility: hidden;
            }

            &::after {
                content: '';
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                transform: translateX(-100%);
                background-image: linear-gradient(
                                90deg,
                                rgba(#fff, 0) 0,
                                rgba(#fff, 0.2) 20%,
                                rgba(#fff, 0.5) 60%,
                                rgba(#fff, 0)
                );
                animation: content_loading 2s infinite;
            }
        }
        .drag_drop-card {
            box-shadow: unset;
            background: #efefef;

            @keyframes content_loading {
                100% {
                    transform: translateX(100%);
                }
            }
        }
    }
}

.drag_drop {

    min-height: 550px;
    border: 1px solid #D3DDEB;
    box-shadow: 0px 6px 7px rgba(40, 41, 61, 0.03), 0px 5px 5px -3px rgba(96, 97, 112, 0.02);
    border-radius: 3px;
    background-color: #ffffff;

    &_dark_text {
        color: #2F343A !important;
        font-family: $sf-pro-display-regular !important;

        svg {
            cursor: pointer;
        }
    }
    
    &_light_text {
        color: #ffffff !important;
        font-family: $sf-pro-display-regular !important;

        &-shadow {
            text-shadow: 0px 1px 0px rgba(0, 0, 0, 0.8);
        }
    }

    &_text_opacity_light {
        opacity: 0.8;
    }

    &_text_opacity_dark {
        opacity: 0.7;
    }

    & > div {
        width: 100%;
        min-width: 230px;
        max-width: 33%;
    }

    .column_title {
        color: #39424E;
    }

    &_context {
        border-right: 1px solid rgba(215, 223, 235, 0.8);
    }

    &-panel {
        min-width: 210px;
        min-height: 100%;
        padding: 0 10px;
    }

    &-card {
        display: flex;
        &--top {

            .expire-date {
                img {
                    width: 10px;
                    margin: 2.5px 5px;
                }
            }
            
            span {
                font-size: 11px;
                font-weight: 700;
                line-height: 11.9px;
                color: rgba(11, 27, 60, 0.8);
            }
            
            small {
                font-size: 10px;
                font-weight: 700;
                line-height: 11px;
                color: #152D4F;
                font-family: $sf-pro-display-regular !important;
            }

            .assign_avatar {
                font-weight: 700;
                font-size: 9px;
                line-height: 8px;
                letter-spacing: 0.3px;
                margin-left: auto;
                width: 24px;
                height: 24px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
            }
        }

        &--name {
            margin: 0;
            font-size: 1rem;
            line-height: 1.3rem;
            color: rgba(11, 27, 60, 0.8);
            font-family: $sf-pro-display-bold !important;
            letter-spacing: -0.4px;

            @media (max-width: 768px) {
                letter-spacing: initial;
                font-size: 12px;
            }
        }

        &--date {
            display: flex;
            align-items: center;
            margin-top: 10px;

            small {
                font-weight: 700;
                font-size: 8px;
                line-height: 9.55px;
                color: rgba(11, 27, 60, 0.7);
            }

            span {
                font-weight: 600;
                font-size: 11px;
                line-height: 12px;
                color: #0B1B3C;
                opacity: 0.7;
                margin-left: 5px;
                time {
                    font-size: 10px;
                }
            }

            img {
                max-width: 20px;
                margin-left: auto;
                border-radius: 50%;
                width: 16px;
                height: 16px;
            }
            .assign_avatar {
                font-size: 9px;
                line-height: 8px;
                letter-spacing: 0.3px;
                margin-left: auto;
                width: 26px;
                height: 26px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                font-family: $sf-pro-display-bold !important;
            }

            .unread_notes {
                font-size: 9px;
                line-height: 8px;
                letter-spacing: 0.3px;
                margin-left: auto;
                width: 26px;
                height: 26px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                font-family: $sf-pro-display-bold !important;
                background-color: rgb(8, 94, 208)
            }
        }
    }

    .filter_hidden {
        max-height: 0 !important;
        min-height: 0 !important;
        height: 0 !important;
        margin: 0 !important;
        padding: 0 !important;
        opacity: 0 !important;
    }
}

#expandCard {
    position:fixed;
    display:none;
    width:200px;
    height:200px;
    -webkit-transition:400ms;
    transition: all 400ms cubic-bezier(0.16, 1, 0.3, 1);
    transform-style:preserve-3d;
    z-index: 1000;
    perspective: 1000px;
    -webkit-perspective: 1000px;
    overflow: hidden;
    border-radius: 6px !important;
    background-color: #fff;
    background-clip: padding-box;
    border: 0;
    pointer-events: auto;
    padding: 20px 10px 20px 20px;
    overflow: visible;

    @media (max-width: 500px) {
        padding: 10px 16px 10px 6px;
    }
}

.expandCardBack {
    display: none;
    background: rgba(0, 0, 0, 0.3);
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    z-index: 201;
}

.modal-close {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
}

.board-color_radio {
    border-radius: 1.5px !important;
    width: 10px !important;
    height: 10px !important;
}

.expandedcard_content {
    opacity: 0;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    max-width: 100%;
    flex-direction: column;
    padding: 0 30px;

    .ant-spin-nested-loading {
        width: 100%;
    }

    @media (max-width: 990px) {
        padding: 0;
        margin-left: 16px;
        .expandcard-header{
            padding: 14px 0;
        }
    }
}

.card-color-line {
    height: auto;
    width: 20px;
    min-width: 11px;
    border-radius: 50px;
    background-color: transparent;

    @media (max-width: 760px) {
        width: 11px;
    }
}

.expandcard-header {
    background-color: $white;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    flex-wrap: wrap;
    align-items: center;
    box-sizing: border-box;
    row-gap: 20px;
    padding: 10px 0 8px;
    margin-top: 20px;

    .card-id {
        position: relative;
        line-height: 22px;
        display: flex;
        align-items: center;
        text-align: left;
        font-size: 1.125rem;
        color: $active-color-blue;
        span{
            font-family: $sf-pro-display-bold !important;
            letter-spacing: -1.1px;
        }
        .value {
            min-width: 100px;
        }
    }

    .edit-number {
        display: flex;
        gap: 5px;
        min-height: 26px;

        img {
            display: none;
        }

        input {
            border: none;
        }

        span {
            border-bottom: 1px solid transparent;
        }

        &.archived {
            img {
                display: none !important;
            }

            span {
                border: none !important;
            }
        }

        &:focus-within{
            img{
                display: flex;
            }
        }

        &:hover {
            img {
                display: flex;
            }

            span {
                border-bottom: 1px solid $border-blue-light;
            }
        }
    }

    @media (max-width: 768px) {
        flex-direction: column;
        align-items: flex-start;
    }
}


.section {
    display: flex;
    flex-wrap: wrap;
    gap: 11px;
    width: 100%;
    border-top: 1px solid $border-blue-light;

    .section-action {
        position: relative;
        flex-wrap: wrap;
        align-items: flex-start;
        justify-content: flex-start;
        display: flex;
        border-top: 1px solid $border-blue-light;
        border-bottom: 1px solid $border-blue-light;
        padding: 18px 0;
        gap: 20px;
        box-sizing: border-box;
        width: 100%;

        .section-item {
            display: flex;
            flex-direction: column;
            flex: 1;

            label {
                white-space: nowrap;
                line-height: 120%;
                letter-spacing: -0.3px;
                font-size: 0.875rem;
                color: $label-color-green;
                font-family: $sf-pro-display-semibold;
                text-transform: uppercase;
            }
            .ant-select-selection-item{
                font-size: 0.875rem;
            }
            .item{
                height: 40px;
                align-items: center;
                gap: 12px !important;
            }
        }

        @media (max-width: 1250px) {
            order: 2;
        }
    }

    .activity-section {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        padding: 9px 0 10px;
        position: relative;
        width: 100%;

        @media (max-width: 1250px) {
            order: 4;
        }
        .block-header{
            padding: 0;
        }
    }
}

.block-header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    cursor: pointer;
    padding: 20px 0;

    b {
        font-size: 1rem;
        letter-spacing: 0.5px;
        line-height: 100%;
        text-transform: uppercase;
        font-family: $sf-pro-display-medium;
        color: $natural-blue;
        text-align: left;
        opacity: 0.7;
    }

    img {
        height: 16px;
        width: 16px;
    }
}
.colour-card {
    border-radius: 3px;
    background-color: $white;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 3px;
    gap: 3px;
    height: 37px;
    width: 45px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.1));
    cursor: pointer;

    .card-left {
        height: 100%;
        width: 3px;
        border-radius: 2px;
    }

    .linegroup {
        display: flex;
        gap: 2px;
        flex-direction: column;
    }

    .rectangleParent {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 5px;
    }

    .frameChild {
        width: 25px;
        position: relative;
        border-radius: 2px;
        background-color: $gray-88;
        height: 2px;
    }

    .frameInner {
        width: 21px;
        position: relative;
        border-radius: 2px;
        background-color: $gray-88;
        height: 2px;
    }
}

.dot {
    height: 26px;
    width: 26px;
    border-radius: 26px;
    justify-content: center;
    align-items: center;
    display: flex;
    cursor: pointer;
    
    .dot-inner{
        height: 26px;
        width: 26px;
        border-radius: 26px;
    }

    &:focus{
        outline: 2px solid $border-blue !important;
    }
}

.ant-space-horizontal {
    overflow-y: auto;
    height: 365px;

    @media (max-width: 768px) {
        overflow-y: initial;
        height: initial;
    }
}