@use '../helpers/fonts' as *;
@use '../helpers/variables' as *;

.planning-board {
    .header {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 57px;
        .action {
            display: flex;
            align-items: center;
            gap: 40px;
            position: absolute;
            right: 15px;

            .btn{
                margin-right: 70px !important;
            }
        }

        @media screen and (max-width: 770px) {
            .action {
                .btn {
                    margin-right: 0 !important;
                }
            }
        }
    }
    .title {
        font-size: 1rem;
        line-height: 24px;
        font-family: $sf-pro-display-semibold !important;
        color: $label-color-green;
        margin-bottom: 0;
        min-height: 40px;
    }
    .pending {
        width: 210px;
        color: #39424E;
        min-height: 550px;
        border: 1px solid #D3DDEB;
        box-shadow: 0px 6px 7px rgb(40 41 61 / 3%), 0px 5px 5px -3px rgb(96 97 112 / 2%);
        border-radius: 3px;
        background-color: #ffffff;

        .header {
            font-weight: bold;
            border-bottom: 1px solid #C7D3E6;
            
            h6 {
                font-weight: bold !important;
            }

            span {
                background: #C7D3E6;
                border-radius: 3px;
                padding: 0 5px;

                img {
                    width: 11px;
                    margin-left: 2px;
                }
            }
        }
    }

    .checkin {
        position: absolute;
        right: 0;
        box-shadow: -4px 1px 10px rgba(0, 0, 0, 0.15);
        height: 100%;
    }

    .board-wrap {
        min-height: 100vh;
    }

    .calendar-container {
        min-height: 550px;
        border: 1px solid #D3DDEB;
        box-shadow: 0px 6px 7px rgb(40 41 61 / 3%), 0px 5px 5px -3px rgb(96 97 112 / 2%);
        border-radius: 3px;
        background-color: #ffffff;
        margin-left: 10px;
        width: calc(100% - 420px);
        position: relative;

        .week-day {
            font-style: normal;
            font-weight: 600;
            font-size: 14px !important;
            line-height: 14px;
        }

        .duration-item{
            background-color: $white;
            &:not(:last-child){
                border-bottom:1px solid $border-blue-light;
                min-height: 201px;
            }
        }

        .leftside {
            z-index: 50;
            position: absolute;
            height: 100%;
            width: 3rem;
            top: 31px;

            & > div {
                position: relative;
                
                .wrap {
                    position: absolute;
                    width: max-content;
                    transform: rotate(-90deg) translateY(-50%);
                    margin-top: -15px;
                    top: 50%;

                    span {
                        color: rgba(0, 81, 186, 0.7);
                        margin: auto;
                    }

                    small {
                        font-size: 9px;
                        color: #152D4F;

                        img {
                            width: 9px;
                            margin-left: 6px;
                            margin-right: 6px;
                        }
                    }
                }
            }
        }
            
        .calendar {
            margin: 0px 0px 0px 3rem;
            padding: 0;
            list-style: none;
            font-size: 1.4rem;
            width: 100%;
            min-width: 1470px;
            display: flex;
            flex-wrap: wrap;
        }
        
        .calendar-day {
            color: #000;
            position: relative;
            border-bottom: 1px solid #C7D3E6;
            border-left: 1px solid #C7D3E6;
            max-width: calc(100% / 7);
            flex-basis: calc(14.28571% - 2px);
            display: block;
            
            &:nth-child(7n) {
                border-right: 1px solid #C7D3E6;
            }

            &:nth-child(n + 57) {
                border-bottom: unset !important;
            }
        }
        
        .calendar-day:nth-child(7n+1)::before {
            content: " ";
            border-top: 1px solid #C7D3E6;
            height: 1px;
            width: 3rem;
            position: absolute;
        }
        
        .calendar-day::after {
            content: " ";
            position: absolute;
        }
        
        .calendar-day__number {
            position: absolute;
            top: .5rem;
            left: .5rem;
        }
        
        @media screen and (max-width: 40rem) {
        
            .calendar-day:nth-child(7n+1)::before {
                left: 0;
                right: 0;
                bottom: calc(100% + .5rem);
                display: block;
                text-align: center;
            }
        
            .calendar-day::after {
                right: calc(100% + .5rem);
                top: .5rem;
            }
        
        
            .calendar-day__content {
                padding: 0 0 20%;
            }
        }
    
        @media screen and (min-width: 40rem) {
            .calendar {
                display: flex;
                flex-wrap: wrap;
            }
        
            .calendar-day {
                flex-basis: calc(14.28571% - 2px);
            }
        
            .calendar-day:nth-child(7n+1)::before {
                right: 100%;
                top: -1px;
            }
        
            .calendar-day::after {
                display: none;
            }
        
            .calendar-day:nth-child(-n+7)::after {
                display: inline;
                bottom: 100%;
                left: -1px;
            }
        
            .calendar-day--not-month {
                display: block;
            }
        
            .calendar-day__content {
                padding-bottom: 100%;
            }
        }
  
    }

    .drag_drop-card--top small {
        font-size: 9px !important;
    }


    .drag_drop-card--name {
        
        span {
            float: right;
        }

        img {
            float: right;
            margin-top: 4px;
        }
    }

    .drag_drop-card--date {
        span {
            font-size: 8px !important;
        }

        .image-wrap {
            padding: 5px;
            margin-left: auto;
        }
    }

    .image-rotation {
        transform: rotate(180deg);
    }

    .pannel {
        background-color: $white;
        gap: 7px;
    }

    .active-color{
        background-color: $blue;
        gap: 7px;
    }

    @media screen and (max-width: 1000px) {
        .board-wrap {
            overflow-x: auto;
            overflow-y: hidden;

            .calendar-container {
                width: auto;
            
                .indiana-scroll-container--native-scroll {
                    overflow: hidden;
                }
            }
            .calendar-day:nth-child(7n+1)::before {
                border-top: none;
            }
            .checkin {
                position: relative;
                margin-left: 10px;
                height: auto;
            }
        }
    }
}


.job-card {
    position: relative;
    box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.04), 0px 2px 4px rgba(96, 97, 112, 0.16);
    border-radius: 3px;
    background-color: $white;
    border: 1px solid $border-blue-light;
    box-sizing: border-box;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 6px 10px 6px 5px;
    gap: 11px;
    min-height: 145px;
    align-items: flex-start;
    margin-bottom: 8px;

    .card-color {
        width: 10px;
        position: relative;
        border-radius: 20px;
        height: 100%;
        background-color: transparent;
    }

    .card-number {
        font-size: 0.625rem;
        text-transform: uppercase;
        display: flex;
        color: $sky;
        text-align: left;
        font-family: $sf-pro-display-bold;
        flex-shrink: 0;
    }

    .card-extra-small-text {
        font-size: 0.563rem;
        line-height: 120%;
        font-weight: 500;
        color: $label-color-green;
        text-align: left;
    }

    .card-name {
        padding: 13px 0px 0px;
        gap: 3px;
        display: flex;
        flex-direction: column;
        font-size: 0.813rem;
        letter-spacing: -0.5px;
        color: $label-color-green;
        text-align: left;

        .card-name-value {
            line-height: 17px;
            font-family: $sf-pro-display-bold !important;
        }

        .sub-text {
            line-height: 16px;
        }

    }

    .content-bottom {
        padding: 14px 0 0 0;
        display: flex;
        gap: 12px;
        flex-direction: column;

        .card-value {
            span {
                font-size: 0.625rem;
                color: $black;
                font-family: $sf-pro-display-bold !important;
            }
        }

        .dots-container {
            .dot {
                height: 18px;
                width: 18px;
                .dot-inner{
                    height: 18px;
                    width: 18px;
                }
            }
        }

        .card-footer {
            border-radius: 6px;
            background-color: $light-gray;
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            padding: 6px 0px;
            box-sizing: border-box;

            .divider {
                width: 1px;
                position: relative;
                background-color: $border-blue-light;
                height: 18px;
            }
            .section-card{
                width: 33%;
                display: flex;
                flex-direction: row;
                gap: 20px;
                justify-content: center;
                align-items: center;
            }
        }
    }

    p {
        margin-bottom: 0;
    }
}

.assign-user {
    position: relative;
    border-radius: 20px;
    width: 18px;
    height: 18px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 5px 4px;
    box-sizing: border-box;
    color: $white;
    letter-spacing: 0.26px;
    line-height: 100%;
    font-size: 0.5rem;
    background-color: $black;

    img{
        height: 18px;
        width: 18px;
        border-radius: 18px;
    }

    span{
        text-transform: uppercase;
    }
}