@use '../helpers/fonts' as *;
@use '../helpers/variables' as *;

.customer {
    height: 100%;

    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        gap: 15px;
    }

    .title {
        font-size: 1rem;
        line-height: 28px;
        font-family: $sf-pro-display-semibold !important;
        color: $label-color-green;
        text-align: left;
        display: inline-block;
    }

    .btn-add-customer {
        border: none;
        border-radius: 2px;
        background-color: $sky;
        height: 34px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        padding: 7px 15px;
        box-sizing: border-box;
        gap: 10px;
        mix-blend-mode: normal;
        cursor: pointer;
        text-align: left;
        font-size: 0.75rem;
        color: $blue-focus-bg;
        font-family: $sf-pro-display-medium !important;

        svg {
            fill: $white;
            margin-right: 0 !important;
            height: 12px;
            object-fit: cover;
            width: 12px;
        }
    }

    .customer-content {
        align-self: stretch;
        border-radius: 3px 3px 0px 0px;
        background-color: $white;
        border: 1px solid $border-blue-light;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        margin: 0px 0px 20px 0px;

        .search-box {
            width: 100%;
            box-sizing: border-box;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            padding: 10px 0px;
            flex-wrap: wrap;


            .search-text {
                position: relative;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: flex-start;
                padding: 8px 20px 5px 20px;
                box-sizing: border-box;
                gap: 10px;
                flex: 1;

                .focus-line {
                    display: none;
                    width: 40%;
                    position: absolute;
                    margin: 0 !important;
                    top: 54px;
                    left: 20px;
                    border-radius: 2px;
                    background-color: $sky;
                    height: 1px;
                    @media (max-width: 590px) {
                        top: 40px;
                        width: 72%;
                    }
                }

                .close-icon {
                    cursor: pointer;
                }

                .input {
                    border: none;
                    font-size: 0.938rem;
                    color: $bright-gray;
                    font-family: $sf-pro-display-medium !important;
                    line-height: 30px;
                    flex: auto;

                    &::placeholder {
                        font-size: 0.75rem;
                        color: $tealish-blue;
                    }

                    &:focus {
                        background-color: $white !important;
                    }

                }

            }

            .btn-export {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: flex-start;
                margin: 0px 20px;
                box-sizing: border-box;
                gap: 7px;
                cursor: pointer;

                .export-icon {
                    height: 15px;
                    width: 15px;
                    object-fit: cover;
                }

                .export-text {
                    font-size: 0.563rem;
                    letter-spacing: 0.7px;
                    line-height: 16px;
                    text-transform: uppercase;
                    font-weight: 600;
                    font-family: $sf-pro-display-semibold !important;
                    color: $label-color-green;
                }
            }
            
            @media (max-width: 365px) {
                .search-text {
                    padding: 8px 10px 5px 8px;
                }
                .btn-export {
                    margin: 0px 8px;
                }
            }
        }

    .ant-table-wrapper {
        border-top: 1px solid $border-blue-light;
        overflow-x: auto;
        .ant-table {

            .ant-table-thead {
                tr {
                    th {
                        background-color: $white;
                        border-bottom: 1px solid $border-blue-light;
                        font-family: $sf-pro-display-bold !important;
                        color: $natural-blue;
                        font-size: 0.563rem;
                        letter-spacing: 0.5px;
                        line-height: 21px;
                        text-transform: uppercase;

                        .ant-table-column-title {
                            font-family: $sf-pro-display-bold !important;
                            white-space: nowrap;
                        }
                    }
                }
            }

            .ant-table-tbody {
                tr {
                    td {
                        border-bottom: 1px solid $border-blue-light;
                        letter-spacing: 0.3px;
                        font-size: 0.75rem;
                        color: $bright-gray;
                        white-space: nowrap;
                    }
                    td.ant-table-column-sort {
                        background-color: $white;
                    }
                }
            }
        }
    }

        .ant-table-thead>tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
            display: none;
        }

        .footer {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            width: 100%;
            margin-top: 20px;
            padding: 20px 100px;

            .first-last {
                border: 1px solid $gray-white;
                border-radius: 2px;
                box-sizing: border-box;
                align-items: center;
                justify-content: center;
                text-transform: uppercase;
                font-size: 0.625rem;
                text-align: center;
                background-color: $white;
                padding: 7px 20px;
                line-height: 24px;
                display: flex;
                color: $active-color-blue;
                font-family: $sf-pro-display-medium !important;
                letter-spacing: 0.5px;
            }

            .first-last:disabled {
                cursor: not-allowed;
                opacity: 0.5;
            }

            .ant-pagination {
                border: 1px solid $gray-white;
                border-radius: 4px;

                .ant-pagination-item,
                .ant-pagination-prev,
                .ant-pagination-jump-next,
                .ant-pagination-jump-prev,
                .ant-pagination-next {
                    margin-right: 0;
                    height: 36px;
                    width: 36px;


                    .ant-pagination-item-link {
                        border: none;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        svg {
                            fill: $btn-bg-blue-dark;
                        }
                    }
                }

                .ant-pagination-disabled {
                    .ant-pagination-item-link {
                        opacity: 0.5;
                    }
                }

                .ant-pagination-item,
                .ant-pagination-jump-next,
                .ant-pagination-jump-prev,
                .ant-pagination-prev {
                    border: none;
                    border-right: 1px solid $gray-white !important;
                    color: $btn-bg-blue-dark;
                    font-size: 0.75rem;

                    a,
                    .ant-pagination-item-container {
                        height: 100%;
                        width: 100%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }

                    .ant-pagination-item-ellipsis {
                        color: $btn-bg-blue-dark;

                    }
                }

                .ant-pagination-item-active {
                    background-color: rgba($color: $gray-white, $alpha: 0.6);
                    border: none;
                    color: $natural-blue;
                }
            }

            @media (max-width: 500px) {
                padding: 20px;
            }

            @media (max-width: 350px) {
                padding: 10px;
            }
        }
    }
}