@use '../helpers/variables' as *;
@use '../helpers/fonts' as *;

.job_status {
    width: 24px;
    height: 9px;
    border-radius: 5px;
    margin: left;
    border: 1px solid rgba(0, 0, 0, 0.2);
}

.list_menu {
    svg {
        width: 35px;
    }
}

.table-toggle-text {
    font-size: 11px;
}

.empty-board {
    .text {
        font-size: 32px;
    }
}

option {
    padding: 5px 10px;
}


.in-out_dates {
    position: relative;

    .item {
        cursor: pointer;
        width: 100px;
        position: relative;
    }

    .react-datepicker__tab-loop {
        position: absolute;;
    }

    .item_label {
        font-size: 0.875rem;
        line-height: 14px;
        letter-spacing: 0.5px;
        text-align: left;
        color: $natural-blue;
        text-transform: uppercase;
        font-family: $sf-pro-display-bold !important;
        opacity: 0.7;
    }
    
    .item_date {
        font-size: 0.875rem;
        line-height: 14px;
        letter-spacing: 0.5px;
        text-align: left;
        color: $black-900;
        font-family: $sf-pro-display-bold !important;
        opacity: 0.7;
    }

    .react-datepicker__portal {
        height: 100%;
        width: 100%;
        background-color: $transparent-black-2;
        justify-content: flex-end;
        align-items: flex-start;
        padding: 82px;
    }

    .react-datepicker {
        border-radius: 12px;
        border: none;
        font-size: 0.875rem;
        color: $meteorite-gray;
    }

    .react-datepicker__header {
        border-top-left-radius: 12px !important;
        border-top-right-radius: 12px !important;
    }

    .react-datepicker__day--selected,
    .react-datepicker__day--in-selecting-range,
    .react-datepicker__day--in-range,
    .react-datepicker__day--keyboard-selected {
        background-color: $btn-bg-blue-dark;
        border-radius: 100px;
        color: $white !important;
    }

    .react-datepicker__day--in-selecting-range:hover,
    .react-datepicker__day--in-range:hover {
        background-color: $btn-bg-blue-dark;
    }

    .react-datepicker__day:hover,
    .react-datepicker__month-text:hover,
    .react-datepicker__quarter-text:hover,
    .react-datepicker__year-text:hover {
        border-radius: 100px;
    }

    .react-datepicker__week {
        justify-content: space-between;
        display: flex;
        margin-bottom: 12px;
        font-family: $sf-pro-display-light !important;
    }

    .react-datepicker__portal .react-datepicker__day {
        width: 2rem;
        line-height: 2rem;
        font-family: $sf-pro-display-light !important;
    }

    .react-datepicker__month-container {
        padding: 20px;
    }

    .react-datepicker__header {
        background-color: $white;
        border: none;
    }

    .react-datepicker__navigation {
        top: 24px;
    }

    .react-datepicker__current-month {
        font-size: 1.25rem !important;
        line-height: 30px;
        font-weight: 500;
        color: $meteorite-gray;
        text-align: center;
        display: inline-block;
    }

    .react-datepicker__day-names {
        margin-top: 20px;
    }

    .react-datepicker__navigation--previous {
        left: 10%;
    }

    .react-datepicker__navigation--next {
        right: 10%;
    }

    .react-datepicker__navigation-icon::before {
        border-color: $meteorite-gray;
        border-width: 2px 2px 0 0;
    }

    .archive_btn {
        height: 100%;
        padding: 4px 16px 4px 20px;
        margin-left: 20px;
        border-left: 1px solid rgba(199, 211, 230, 1);

        svg {
            cursor: pointer;
        }

        .archive_modal {
            background: white;
            border-radius: 6px;
            box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.1);
            border: 1px solid rgba(199, 211, 230, 1);
            max-width: 300px;
            position: absolute;
            top: 50px;
            z-index: 1;
            right: 0;
            padding: 30px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 14px;
            width: 300px;

            h4 {
                font-size: 1.125rem;
                font-weight: 700;
                line-height: 21px;
                text-align: center;
                color: rgba(57, 66, 78, 1);
                margin-bottom: 26px;
            }

            .btn-group {
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 20px;
                width: 100%;
            }

            .archive_yes {
                background: rgba(36, 109, 203, 1);
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 10px;
                color: white;
                font-size: 0.75rem;
                font-weight: 500;
                line-height: 21px;
                text-align: left;
                border: none;
                box-shadow: none;
                outline: none;
                padding: 6.5px 10px;
                width: 100%;
                max-width: 108px;
                border-radius: 2px;
            }

            .archive_no {
                background: rgba(235, 244, 255, 1);
                border: 1px solid rgba(208, 226, 248, 1);
                border-radius: 2px;
                color: rgba(93, 111, 136, 1);
                font-size: 0.75rem;
                font-weight: 500;
                line-height: 21px;
                text-align: left;
                padding: 6.5px 10px;
                width: 100%;
                max-width: 59px;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .description {
                display: flex;
                gap: 7px;
                align-items: center;
                justify-content: center;

                svg {
                    min-width: 20px;
                    height: 20px;
                }

                span {
                    font-size: 0.625rem;
                    font-weight: 400;
                    line-height: 12.6px;
                    text-align: left;
                    color: rgba(57, 66, 78, 1);
                }
            }

            .unarchive_wrap {
                cursor: pointer;
    
                span {
                    font-size: 0.875rem;
                    font-weight: 500;
                    line-height: 20px;
                    letter-spacing: 0.3;
                    text-align: left;
                    color: rgba(93, 111, 136, 1);
                }
            }

            .close {
                position: absolute;
                top: 15px;
                right: 15px;
                cursor: pointer;
                height: 15px;
                width: 15px;
            }
        }
        
    }

    @media (max-width: 800px) {
        .react-datepicker__portal {
            padding-right: 0;
            padding-left: 0;
            justify-content: center;
        }

        .react-datepicker__month-container {
            padding: 10px;
        }

        .react-datepicker__navigation {
            top: 16px;
        }
    }

    @media (max-width: 768px) {
        .item {
            width: fit-content;
        }

        .archive_btn {
            margin-left: 15px;;
            padding-left: 15px;
        }
    }

    @media (max-width: 560px) {
        .react-datepicker__navigation--previous {
            left: 2px;
        }

        .react-datepicker__navigation--next {
            right: 2px;
        }
    }
}

.archive-modal_overlay {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.3);
    transform: scale(3);
}

.pulseAni {
    animation: pulseGlow 3s ease-in-out;
}

iframe:not(.racer-modal iframe) {
    display: none;
}

.empty-card {
    display: flex;
    flex-direction: row;
    height: 185px;
    background-color: $white;
    border: 1px solid $border-blue-light;
    margin: 10px;
    border-radius: 6px;
    box-shadow: 0px 4px 15px $transparent-black-1;
    gap: 10px;
    padding: 10px;

    .rectangleDiv {
        width: 10px;
        position: relative;
        border-radius: 20px;
        background-color: $gray-white;
    }

    .skeleton {
        background-color: $quil-gray;
        border-radius: 6px;
    }

    .first-col {
        display: flex;
        gap: 5px;
        flex-direction: column;
    }

    .second-col {
        display: flex;
        flex-direction: column;
        gap: 5px;
        margin-top: 18px;
    }

    .third-col {
        margin-top: 14px;
        display: flex;
        gap: 12px;
        flex-direction: column;
    }

    .skl-1 {
        height: 12px;
        width: 50px;
    }

    .skl-2 {
        height: 12px;
        width: 72px;
    }

    .skl-3 {
        width: 115px;
        height: 12px;
    }

    .skl-sort {
        width: 27.6px;
        height: 12px;
    }

    .skl-4 {
        width: 56px;
        height: 12px;
    }

    .skl-button {
        width: 100%;
        height: 31px;
        border-radius: 30px;
    }
}

.empty-jobs-modal {
    padding: 16px;

    .content-container {
        display: flex;
        flex-direction: column;
        gap: 50px;

        .details {
            display: flex;
            flex-direction: column;
            gap: 40px;
            color: $bright-gray;
            letter-spacing: 0.51px;
            text-align: center;

            .title {
                font-size: 1.375rem;
                line-height: 120%;
                font-family: $sf-pro-display-bold !important;
                margin-bottom: 0;
            }

            .body-text {
                margin-top: 27px;
                font-size: 1.056rem;
                line-height: 27px;
            }

            .new-job-card {
                display: flex;
                border-radius: 3px;
                background-color: $white;
                border: 1px solid $border-blue-light;
                box-sizing: border-box;
                align-items: center;
                justify-content: flex-start;
                gap: 20px;
                cursor: pointer;
                position: relative;
                width: fit-content;
                padding: 26px 20px;
                align-self: center;

                &:hover {
                    box-shadow: 0 4px 10px $transparent-black-1;
                }

                .icon-add {
                    background-color: $sky;
                    height: 28px;
                    width: 28px;
                    justify-content: center;
                    align-items: center;
                    border-radius: 100%;

                    svg {
                        fill: $white !important;
                        height: 13px;
                        width: 13px;
                        margin-top: 3px;
                    }
                }

                .card-title {
                    font-size: 1.563rem;
                    line-height: 140%;
                    color: $bright-gray;
                    font-family: $sf-pro-display-semibold !important;
                }
            }
        }

        .footer {
            display: flex;
            justify-content: center;

            .ant-checkbox-wrapper{
                color: $label-color-green;
                font-size: 0.75rem;
                line-height: 140%;
                letter-spacing: 0.51px;
            }
        }
    }
}

.header-job {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 15px;

    .title{
        font-size: 1rem;
        line-height: 28px;
        font-family: $sf-pro-display-semibold !important;
        color: $label-color-green;
        text-align: left;
        display: inline-block;
    }
    .basecode_filled-bright {
        span {
            margin-bottom: 0;
        }
    }

    @media (max-width: 560px) {
        align-items: flex-start;
        .right-content {
            flex-direction: column;
            align-items: flex-end !important;
            .basecode_filled-bright{
                margin-right: 0 !important;
            }
        }
    }
}

.jobs-container{
    align-self: stretch;
    border-radius: 3px 3px 0px 0px;
    background-color: $white;
    border: 1px solid $border-blue-light;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin: 0px 0px 20px 0px;
    .ant-table-wrapper{
        width: 100%;
    }

    .search-box {
        width: 100%;
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 10px 0px;
        flex-wrap: wrap;


        .search-text {
            position: relative;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            padding: 8px 20px 5px 20px;
            box-sizing: border-box;
            gap: 10px;
            flex: 1;

            .focus-line {
                display: none;
                width: 40%;
                position: absolute;
                margin: 0 !important;
                top: 54px;
                left: 20px;
                border-radius: 2px;
                background-color: $sky;
                height: 1px;
                @media (max-width: 590px) {
                    top: 40px;
                    width: 72%;
                }
            }

            .close-icon {
                cursor: pointer;
            }

            .input {
                border: none;
                font-size: 0.938rem;
                color: $bright-gray;
                font-family: $sf-pro-display-medium !important;
                line-height: 30px;
                flex: auto;

                &::placeholder {
                    font-size: 0.75rem;
                    color: $tealish-blue;
                }

                &:focus {
                    background-color: $white !important;
                }

            }

        }

        .btn-export {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            margin: 0px 20px;
            box-sizing: border-box;
            gap: 7px;
            cursor: pointer;

            .export-icon {
                height: 15px;
                width: 15px;
                object-fit: cover;
            }

            .export-text {
                font-size: 0.563rem;
                letter-spacing: 0.7px;
                line-height: 16px;
                text-transform: uppercase;
                font-weight: 600;
                font-family: $sf-pro-display-semibold !important;
                color: $label-color-green;
            }
        }
        @media (max-width: 365px) {
            .search-text {
                padding: 8px 10px 5px 8px;
            }
            .btn-export {
                margin: 0px 8px;
            }
        }
    }

    .ant-table-wrapper {
        border-top: 1px solid $border-blue-light;
        overflow-x: auto;
    .ant-table {

        .ant-table-thead {
            tr {
                th {
                    background-color: $white;
                    border-bottom: 1px solid $border-blue-light;
                    font-family: $sf-pro-display-bold !important;
                    color: $natural-blue;
                    font-size: 0.563rem;
                    letter-spacing: 0.5px;
                    line-height: 21px;
                    text-transform: uppercase;
                    white-space: nowrap;

                    .ant-table-column-title {
                        font-family: $sf-pro-display-bold !important;
                    }
                }
            }
        }

        .ant-table-tbody {
            tr {
                td {
                    border-bottom: 1px solid $border-blue-light;
                    letter-spacing: 0.3px;
                    font-size: 0.75rem;
                    color: $bright-gray;
                    white-space: nowrap;
                }
            }
        }
    }
}

    .ant-table-thead>tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
        display: none;
    }

    .footer {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        margin-top: 20px;
        padding: 20px 100px;

        .first-last {
            border: 1px solid $gray-white;
            border-radius: 2px;
            box-sizing: border-box;
            align-items: center;
            justify-content: center;
            text-transform: uppercase;
            font-size: 0.625rem;
            text-align: center;
            background-color: $white;
            padding: 7px 20px;
            line-height: 24px;
            display: flex;
            color: $active-color-blue;
            font-family: $sf-pro-display-medium !important;
            letter-spacing: 0.5px;
        }

        .first-last:disabled {
            cursor: not-allowed;
            opacity: 0.5;
        }

        .ant-pagination {
            border: 1px solid $gray-white;
            border-radius: 4px;

            .ant-pagination-item,
            .ant-pagination-prev,
            .ant-pagination-jump-next,
            .ant-pagination-jump-prev,
            .ant-pagination-next {
                margin-right: 0;
                height: 36px;
                width: 36px;


                .ant-pagination-item-link {
                    border: none;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    svg {
                        fill: $btn-bg-blue-dark;
                    }
                }
            }

            .ant-pagination-disabled {
                .ant-pagination-item-link {
                    opacity: 0.5;
                }
            }

            .ant-pagination-item,
            .ant-pagination-jump-next,
            .ant-pagination-jump-prev,
            .ant-pagination-prev {
                border: none;
                border-right: 1px solid $gray-white !important;
                color: $btn-bg-blue-dark;
                font-size: 0.75rem;

                a,
                .ant-pagination-item-container {
                    height: 100%;
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }

                .ant-pagination-item-ellipsis {
                    color: $btn-bg-blue-dark;

                }
            }

            .ant-pagination-item-active {
                background-color: rgba($color: $gray-white, $alpha: 0.6);
                border: none;
                color: $natural-blue;
            }
        }

        @media (max-width: 500px) {
            padding: 20px;
        }

        @media (max-width: 350px) {
            padding: 10px;
        }
    }
}