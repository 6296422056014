@use '../helpers/variables' as *;
@use '../helpers/fonts' as *;

.user-edit {
    .tool-title {
        font-size: 1rem;
        line-height: 24px;
        color: $label-color-green;
        font-family: $sf-pro-display-semibold !important;
        margin-bottom: 0;
    }
    .header {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 57px;
        justify-content: space-between;
        
        .title {
            font-size: 1rem;
            line-height: 24px;
            font-family: $sf-pro-display-semibold !important;
            color: $label-color-green;
            margin-bottom: 0;
        }
        .action {
            display: flex;
            align-items: center;
            gap: 20px;
            .btn {
                margin-right: 0 !important;
            }
        }
    }

    .text-danger, .text-success {
        font-size: 11px;
        transition: all 0.3s ease-out 0s;
    }

    .profile-header {
        background: #F6FAFF;
        border: 1px solid #C7D3E6;
        border-radius: 3px;
    }

    .profile-body {
        background: #FFFFFF;
        border: 1px solid #C7D3E6;
        border-radius: 3px;

        @media (max-width:768px) {
            .content {
                margin-top: 0 !important;
                .mobile-mt {
                    margin-top: 30px;
                }
            }
        }
    }

    .file_uploader {
        width: 94px;
        height: 94px;
        min-width: initial;
        position: relative;
        cursor: pointer;
    }

    .upload_img {
        position: absolute;
        right: 6px;
        bottom: -6px;
        cursor: pointer;
    }

    .user-contact-view {
        .username {
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 28px;
            color: #39424E;
            font-family: $sf-pro-display-regular !important;
        }

        .userid {
            letter-spacing: 0.5px;
            text-transform: uppercase;
            font-weight: 700;
            font-size: 9px;
            line-height: 15px;
        }

        .userrole {
            font-family: $sf-pro-display-thin !important;
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
            color: #9D9D9D;
        }

        .useremail, .userphone {
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
            color: #605F5F;
            font-family: $sf-pro-display-thin !important;
        }
    }

    .lastlogin {
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 21px;
        text-align: right;
        color: #C4C4C4;
    }

    .active {
        font-family: $sf-pro-display-medium !important;
        font-style: normal;
        font-size: 11px;
        line-height: 18px;
        letter-spacing: 0.5px;
        color: rgba(0, 0, 0, 0.55);

    }

    .resetpass {
        font-family: $sf-pro-display-regular !important;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 21px;
        color: #1658AC;
        margin-right: 0 !important;
    }

    .ant-switch-checked {
        background-color: $status-green;
    }

    .ant-switch-checked .ant-switch-handle {
        left: calc(100% - 14px - 2px);
    }

    .ant-switch-handle {
        width: 14px;
        height: 14px;
    }

    .ant-switch {
        min-width: 36px;
        height: 18px;
    }

    .ant-collapse, .ant-collapse-content {
        border: none !important;
        background-color: transparent !important;
    }
    
    .block {
        .toggle {
            border: none;
            outline: none !important;
            box-shadow: none;
        }

        .ant-divider-horizontal {
            width: 95%;
            min-width: 95%;
        }

        .ant-divider-dashed {
            border-color: #C7D3E6;
        }

        .ant-divider-inner-text {
            font-family: $sf-pro-display-medium !important;
            font-style: normal;
            font-size: 16px;
            line-height: 19px;
            text-align: center;
            color: #605F5F;
        }
    }

    .content {

        position: relative;

        input, .ant-select-selector {
            font-family: $sf-pro-display-regular !important;
            height: 41px !important;
            font-style: normal;
            font-weight: 500;
            font-size: .9rem;
            line-height: 20px;
            letter-spacing: 0.3px;
            color: #39424E;
        }

        input {
            padding-left: 0;
        }

        .ant-select:not(.ant-select-customize-input) .ant-select-selector {
            border: 1px solid #C7D3E6;
            border-radius: 3px;
        }

        .ant-select-single .ant-select-selector .ant-select-selection-item,
        .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
            line-height: 40px !important;
        }

        .edit-wrap {

            &.title {
                font-size: 9px;
                text-transform: uppercase;
                font-weight: 600;
            }

            input {
                background-color: transparent !important;
                border: none;
                box-shadow: none !important;
                width: 100%;
                border-bottom: 1px #C7D3E6 solid;
    
                &:hover, &:focus {
                    border-bottom: 1px #C7D3E6 solid;
    
                    & + img {
                        display: initial;
                        position: absolute;
                        top: 35px;
                    }
                }

                &#phone_number {
                    &:hover, &:focus {
                        & + img {
                            right: 40px;
                        }
                    }
                }
            }

            img {
                display: none;

                &.edited {
                    display: initial;
                    position: absolute;
                    top: 35px;
                }
            }

            input#phone_number + img.edited {
                right: 40px;
            }
        
            label {
                font-family: $sf-pro-display-regular !important;
                text-transform: uppercase;
                font-style: normal;
                font-weight: 700;
                font-size: 0.7rem;
                line-height: 21px;
                letter-spacing: 0.5px;
                color: #5D6F88;
                margin: 0;
            }
        }

        .org-name {
            font-size: 12px;
            background-color: #F2F7FE;
            color: #2B79E0;
        }

    }

    th.ant-table-row-expand-icon-cell {
        visibility: hidden;
    }

    .ant-table-row-expand-icon-cell, .ant-table-expanded-row {
        position: relative;
        z-index: 111;
        left: 48px;
    }

    .ant-table-thead > tr > th, .ant-table-thead th.ant-table-column-sort, .ant-table-tbody > tr > td {
        background-color: white;
    }

    .ant-table-column-title, th.ant-table-cell {
        font-family: $sf-pro-display-regular !important;
        font-style: normal;
        font-weight: 700;
        font-size: 11px;
        line-height: 21px;
        letter-spacing: 0.5px;
        text-transform: uppercase;
        color: rgba(93, 111, 136, 1);
        opacity: 0.7;
    }

    td.ant-table-cell {
        font-family: $sf-pro-display-regular !important;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 20px;
        letter-spacing: 0.3px;
        color: #39424E;
    }

    .org-name {
        font-family: $sf-pro-display-regular !important;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 20px;
        letter-spacing: 0.3px;
        color: #2B79E0;
    }

    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
        color: white;
    }

    .ant-tabs-ink-bar {
        top: 8px;
        height: 30px !important;
        z-index: -1 !important;
        background: #246DCB !important;
        border-radius: 4px;
    }

    .ant-tabs-top > .ant-tabs-nav::before {
        font-family: $sf-pro-display-medium !important;
        content: "Show:";
        margin-right: 10px;
        position: relative;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        text-align: center;
        color: #3F3B3B;
        border: none;
    }

    .ant-tabs-tab-btn {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        text-align: center;
        padding: 0 15px;
    }

    .ant-tabs-tab-active .ant-tabs-tab-btn {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        text-align: center;
        color: #5D6F88;
    }

    .org-info {
        width: 65%;
    }

    .pro-info {
        width: 30%;
    }

    ul {
        list-style-type: none;
    }

    .tree {
        position: relative;
        z-index: 1;
        padding: 0;

        & ul {
            margin:0 0 0 20px;
            padding:0;
            list-style:none;
            color: blue;
            position:relative;
            z-index: 2;
            & li {
                padding-right: 0px !important;
            }
        }

        &:before, ul:before {
            content:"";
            display:block;
            width: 0;
            position:absolute;
            top:0;
            bottom:0;
            left:0;
            border-left:1px solid;
            background: white;
            z-index: -1;
        }

        & li {
            font-weight:bold;
            line-height: 2;
            margin:0;
            padding: 6px;
            position:relative;
            z-index: -1;
            &:before {
                border-top:1px solid;
                content:"";
                display:block;
                height:100%;
                left:0;
                margin-top: 0px;
                position: absolute;
                top: 18px;
                width: 15px;
            }
            &:last-child:before {
                background: white;
                bottom:0;
                height:auto;
                top: 20px;
            }
        }
            & > li:before {
            border-top:none !important;
            }
            & li > div {
                margin-left: 10px;
                font-weight:bold;
                padding-left: 1px;

                & .round {
                    font-family: $sf-pro-display-thin !important;
                    border-radius: 100%;
                    color: white;
                    font-size: 9px;
                }
            }

            .title {
                letter-spacing: 0.3px;
                color: #2B79E0;
            }
        }
      
        ul.tree {
            margin-left: 0;
            &:before {
            border-left:none;
        }
    }

    .collapse-css-transition{
        overflow: hidden;
        .new-user_row{
            min-width: 535px;
        }
    }
    @media (max-width:590px) {
        .mobile-status {
            display: flex;
            align-items: flex-start;
            flex-direction: column;
            margin-top: 8px !important;
            span {
                margin-left: 3px !important;
            }
        }
        .users-table{
            .ReactTable{
                width: fit-content;
            }
        }
    }
    @media (max-width:465px) {
        .header{
            flex-wrap: nowrap;
            align-items: flex-start;
            .action{
                flex-wrap: wrap;
                justify-content: flex-end;
                .text-danger, .text-success {
                    order: 1;
                }
            }
        }
    }
    .users-table{
        display: block;
        overflow-x: auto;
        padding-bottom: 10px;
        .ReactTable {
            .rt-table {
                overflow: hidden;
            }
        }
    }
}

.weekDays-selector {
    span {
        font-size: 9px;
        line-height: 21px;
        font-weight: 700;
        margin-right: 10px;
    }

    input {
        display: none!important;
    }

    input[type=checkbox] + label {
        display: inline-block;
        border-radius: 50%;
        border: 1px solid #dddddd;
        height: 32px;
        width: 32px;
        margin-right: 10px;
        margin-bottom: 0;
        font-size: 14px;
        line-height: 30px;
        text-align: center;
        cursor: pointer;
        font-weight: 600;
    }

    input[type=checkbox]:checked + label {
        background: #006EFF;
        color: #ffffff;
    }

    &:nth-child(2) {
        & > div {
            height: 20px;
        }
    }
}

.picker-cover {
    position: fixed;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
}

.picker-popover {
    position: absolute;
    z-index: 2;
}

.custom-status {
    span {
        font-size: 13px;
        font-weight: 600;
        line-height: 19px;
        color: black;
    }
}

.user-edit {

    .drag_drop > div {
        min-width: unset !important;
        max-width: unset !important;
        width: auto !important;
    }

    .drag_drop {
        min-height: fit-content;
    }

    .card-container {
        width: 100%;
        min-width: 210px;
        max-width: 33%;
        border-right: 1px solid rgba(215, 223, 235, 0.8);

    }

    .drag-title {
        font-size: 12px;
        line-height: 14px;
        color: black;
        font-weight: 600;
        border-bottom: 1px solid rgba(215, 223, 235, 0.8);
    }

    .drag-block {
        background-color: rgba(242, 247, 254, 1);
        border: 1px dashed rgba(199, 211, 230, 1);
        width: 100%;
        height: 70px;
    }

    .profile-user-avatar {
        width: fit-content;
        position: relative;
        margin-right: 15px;

        img {
            width: 22px;
            height: 22px;
            box-shadow: 0px 5px 5px -3px rgb(96 97 112 / 2%);
            filter: drop-shadow(0px 0px 1px rgba(0, 0, 0, 0.04)) drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.32));
            border-radius: 50%;
            flex: none;
            order: 0;
            flex-grow: 0;
        }
    }

    .user_avatar {
        font-size: 9px;
        line-height: 8px;
        letter-spacing: 0.3px;
        position: relative;
        margin-right: 10px;
        width: 26px;
        min-width: 26px;
        height: 26px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        font-family: $sf-pro-display-bold !important;
        border: 1px solid;
    }

    .mark-green {
        width: 7px;
        height: 7px;
        position: absolute;
        top: 0;
        right: 0;
        border-radius: 50%;
        border: 1px solid white;
    }

    .inactive {
        color: rgba(196, 196, 196, 1) !important;
    }

    .pagination-bottom {
        display: none !important;
    }

    .ReactTable {
        border: none !important;
    }

    .ReactTable .rt-thead.-header {
        box-shadow: none !important;
    }

    .ReactTable .rt-thead .rt-th, .ReactTable .rt-thead .rt-td {
        border-right: none !important;
        transition: none !important;
        box-shadow: none !important;
        text-align: left;
        padding-right: 1rem !important;
        padding-left: 1rem !important;
    }

    .ReactTable .rt-tbody .rt-td {
        border-right: none !important;
        padding-right: 1rem !important;
        padding-left: 1rem !important;
        font-size: 12px;
    }

    .ReactTable .rt-tbody .rt-expandable {
        display: none !important;
    }

    .ReactTable .rt-thead .rt-th {
        &:first-child {
            display: none !important;
        }
    }

    .ReactTable .rt-tr {
        border-bottom: 1px solid rgba(199, 211, 230, 1);
    }

    .ReactTable .rt-thead .rt-resizable-header-content {
        font-size: 9px;
        text-transform: uppercase;
        font-weight: 600;
    }

    .name {
        color: #085ED0;
        font-weight: 600;
    }

    .subcomponent {
        margin-right: 0 !important;
        margin-left: 0 !important;
        display: flex;
        align-items: center;
        animation: fadeIn 0.3s ease-in-out;
        opacity: 1;
        overflow: hidden;

        label {
            font-size: 9px;
            font-weight: 600;
        }

        span, p, input, select, option {
            font-size: 12px;
        }

        .edit-wrap {
            img {
                display: flex !important;
            }
        }

        .item {
            padding-right: 1rem !important;
            padding-left: 1rem !important;
            padding: 7px 5px;
            flex: 1;
        }
        .disable-input{
            input{
                border: none;
                &:hover, &:focus {
                    border: none !important;
                }
            }
        }
    }

    .user_active {
        background-color: rgba(95, 221, 151, 1);
    }

    .user_inactive {
        background-color: rgba(244, 99, 99, 1);
    }

    .new-user_row {
        & > div {
            width: 15%;
            flex: 100 0 auto;
            padding-right: 1rem;
            padding-left: 1rem;

            input {
                border-bottom: 1px #C7D3E6 solid !important;
            }
        }
    }
}

.user_modal {
    &-divider {
        &::after {
            @media (min-width: 768px) {
                width: 50% !important;
            }
        }
    }

    .ant-modal-title {
        font-size: 20px;
        color: #085ED0;
        line-height: 24px;
        font-weight: 400;
    }

    .ant-modal-header {
        border: none;
        padding-top: 40px;
    }

    .ant-modal-close {
        top: 20px;
    }

    .ant-modal-body {
        padding: 0 !important;

        .content {
            & > div {
                padding: 24px;
            }
        }
    }

    .hide-role {
        color: #085ED0;
        font-size: 13px;
        line-height: 30px;
        font-family: $sf-pro-display-regular !important;
        cursor: pointer;
    }

    .role-description {
        background: #F2F7FE;
        border: 1px solid #C7D3E6;
        border-radius: 3px;
    }
}

.file_uploader {
    width: 160px;
    height: 160px;
    min-width: 160px;
    background-image: url('../../images/user_avatar.svg');
    background-size: cover;
    background-repeat: no-repeat;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 50%;
        object-position: center;
    }
}

.select-wrap {
    border-bottom: 2px solid #EAEAEA;

    .ant-select-arrow {
        display: none;
    }

    img {
        position: absolute;
        right: 20px;
        top: 13px;
        user-select: none;
        pointer-events: none;
    }
}

.user-email {
    line-height: 3;
}

.is-rotated {
    transform: rotate(180deg);
    transition: .2s;
}

.no-rotated {
    transition: .2s;
}

.pl-30 {
    padding-left: 30px !important;
}

.cursor-pointer {
    cursor: pointer;
}