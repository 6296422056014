@use '../helpers/variables' as *;
@use '../helpers/fonts' as *;
@use '../helpers/mixins' as *;

body {
    overflow-x: hidden;
}

h1, h2, h3, h4, h5, h6, div, span, p {
    font-family: $sf-pro-display-regular !important;
}

select, input, button {
    font-family: $sf-pro-display-regular !important;
}

.select-box-blue {
    background-color: $blue !important;
    border: 1px solid rgba(43, 117, 214, 0.2) !important;
    border-radius: 2px !important;
    
    &-round {
        background-color: $blue !important;
        border: 1px solid #C7D3E6 !important;
        border-radius: 3px;
        
        &:focus {
            background-color: white !important;
            border-color: $primary-dark !important;
        }
    }
}

.cursor {
    cursor: pointer;
}

.wrapper {
    background-color: #f9f9f9;
}

.select-box-bottom-line {
    background-color: #fff !important;
    border-bottom: 1px solid #C7D3E6 !important;
    border-radius: 0 !important;
    
    .css-eowmo0-control {
        border: none !important;
    }

    &:focus-within{
        outline: none;
    }
}

aside, .ant-layout-sider {
    flex: 0 0 330px !important;
    max-width: 330px !important;
    min-width: 330px !important;
    width: 330px !important;    
}

.ant-layout-sider.ant-layout-sider-collapsed {
    flex: 0 0 54px !important;
    max-width: 54px !important;
    min-width: 54px !important;
    width: 54px !important;
}

.header-modal-mark {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    z-index: -111;
    background: rgba(0, 0, 0, 0.3);

    &.show {
        z-index: 99;
    }
}

h2 {
    font-size: 18px;
}

.form-label {
    color: rgba($primary, 0.6);
    font-size: $small-size !important;
}

.form-select {
    height: 37px;

    &:focus {
        box-shadow: none;
    }
}

.navbar-list {
    flex-direction: row !important;
}

.full-height {
    height: calc(100vh - 150px);
    overflow-y: auto;
    overflow-x: hidden;
    padding-bottom: 50px;
    
    &-top {
        overflow-y: auto;
        overflow-x: hidden;
        padding-bottom: 50px;
        height: calc(100vh - 85px);
    }

    @media (min-width: 993px) {
        padding-bottom: 100px;
    }

}

.h-height {
    height: 100%;
}

// scrollbar
::-webkit-scrollbar {
    width: 4px;
}
  
::-webkit-scrollbar-track {
    border-radius: 10px;
    background: #DBE2ED;
}

::-webkit-scrollbar-thumb {
background: #7CB0F4; 
border-radius: 7px;
}

.out-btn-primary {
    border-color: $primary;
    color: $primary;
    background-color: transparent;
    font-size: 14px;
    border-radius: 3px;
    font-family: $sf-pro-display-medium;
}

.borde-gray-bottom {
    border-bottom: 1px solid #EAEAEA;
}

.btn-outline-primary:hover {
    background-color: $active-color-blue;
    border-color: $active-color-blue;
}

.btn-primary:hover {
    background-color: $active-color-blue;
    border-color: $active-color-blue;
}

.divider-label {
    h6 {
        background-image: url('../../images/line-bg.svg');
        background-repeat: repeat-x;
        background-position: center right;
    
        span {
            background-color: #fff;
        }
    }
}

.select-hidden {
    display: none;
    visibility: hidden;
    padding-right: 10px;
}

.select {
    cursor: pointer;
    display: inline-block;
    position: relative;
    font-size: 16px;
    max-width: 260px;
    font-size: 14px;
    border: 1px solid rgba(43, 117, 214, 0.2) !important;
    border-radius: 2px !important;
    height: 42px;
    width: 100%;
    color: #777D74;
}

.select-styled {
    position: absolute; 
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 9px 12px;
    transition: all 0.2s ease-in;
    font-family: sf-pro-display-regular !important;
    &:after {
        content:"";
        width: 0.8em;
        height: 0.8em;
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
        position: absolute;
        top: 16px;
        right: 15px;
    }

    &:active, &.active {
        background-color: $blue-focus-bg;
        &:after {
            transform: rotate(180deg);
            border-color: transparent transparent #fff transparent;
        }
    }
}

.select-options {
    display: none; 
    position: absolute;
    top: 100%;
    right: 0;
    left: 0;
    z-index: 999;
    margin: 0;
    padding: 0;
    list-style: none;
    background-color: $blue-focus-bg;
    height: fit-content;
    overflow-y: scroll;
    max-height: 200px;

    li {
      margin: 0;
      padding: 6px 0;
      text-indent: 15px;
      border-top: 1px solid $blue-focus-bg;
      
      &:hover, 
      &.is-selected {
        color: #374948;
        background: $blue-focus-bg;
      }
      &[rel="hide"] {
        display: none;
      }
    }
}

.react-datepicker__year-wrapper {
    max-width: 210px !important;
}

.react-datepicker__navigation-icon {
    top: 10px !important;
}

.react-datepicker__input-container {
    input {
        border: none !important;
        width: 90px;
        color: #777D74;
        @media (max-width: 1200px) {
            width: 100%;
        }
    }
}

.image-select {
    .css-eowmo0-control {
        border: none;
        border-radius: 0 !important;
        padding-bottom: 2px;
        border-bottom: 1px solid #C7D3E6;

        .css-1u9des2-indicatorSeparator {
            display: none;
        }
    }
}

.css-ghtos7-control:hover, .css-ghtos7-control:focus, .css-ghtos7-control:visited, .css-ghtos7-control:focus-visible, .css-ghtos7-control:focus-within {
    border: none !important;
    box-shadow: none !important;
    border-color: transparent !important;
    outline: none !important;
    border-width: 0 !important;
}

.imageDateInput {
    position: relative;
    background: #FFFFFF;
    border: none;
    border-bottom: 1px solid #C7D3E6 !important;
    border-radius: 0px;
    height: 41px;
    padding: 7px;
    margin-bottom: 0 !important;

    &:focus-within {
        background-color: $blue-focus-bg !important;
    }

    img {
        height: fit-content;
        pointer-events: none;
        position: absolute;
        right: 7px;
        cursor: pointer;
    }
}

.css-tsrali-control {
    height: 41px;
    background-color: transparent !important;
}

.css-lzfvzg-singleValue {
    background-color: transparent !important;
}

.toogle-btn-group {
    background: #EBF4FF;
    @include borderLine(1px, solid, rgba(0, 110, 255, 0.14));
    border-radius: 65px;
    height: 32px;
    z-index: 0;
    width: fit-content;
    position: relative;
    width: 100%;
    max-width: 200px;
    text-transform: uppercase;
    cursor: pointer;

    & > div {
        text-align: center;
        font-size: 10px;
        width: 50%;
        z-index: 4;
        height: 100%;
        line-height: 30px;

        &.active {
            color: white;
        }

    }

    span {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        z-index: 1;
        transition: 0.3s ease left;
        background: #085ED0;
        border-radius: 40px;
        width: 50%;

        &.active {
            left: 50%;
        }
    }

    .btn {
        background-color: transparent !important;
        border: none !important;
        box-shadow: none !important;
        color: #777D74;
        height: 100%;
        font-size: 10px;
        padding-left: 15px;
        padding-right: 15px;
        text-transform: uppercase;

        &.active {
            background: $active-color-blue !important;
            border-radius: 40px !important;
            color: #fff;
            &:focus, &:hover {
                color: #fff !important;
            }
        }

    }
}

.gray-bg {
    background-color: transparent !important;
    color: #777D74;
    font-size: 13px;

    // @media (min-width: 990px) {
    //     margin-bottom: 0 !important;
    // }
}

.rangeslider__handle-label, .rangeslider-horizontal .rangeslider__handle:after {
    display: none !important;
}

.rangeslider__handle-label, .rangeslider__handle-tooltip {
    color: white;
}

.rangeslider__handle {
    box-shadow: none !important;
    z-index: 1;
}

.rangeslider {
    background-color: #EBECEC !important;
}

.rangeslider-horizontal {
    height: 4px !important;
    border-radius: initial !important;
}

.rangeslider-horizontal .rangeslider__handle {
    border-radius: initial !important;
    border: none !important;
    background-image: url('../../images/slider-mark.svg') !important;
    background-color: transparent !important;
    background-repeat: no-repeat !important;
    width: 11px !important;
    height: 17px !important;

    span {
        color: white;
    }
}

.rangeslider__label-item {
    font-size: 10px !important;
    color: $label-color-green;
    
    &:last-child {
        left: 1.5px !important;

        &:before {
            left: 2px;
        }
    }

    &:before {
        content: ' ';
        width: 2px;
        height: 7px;
        background-color: #EBECEC;
        position: absolute;
        left: 6px;
        top: -10px;
    }
}

.rangeslider, .rangeslider .rangeslider__fill, .btn-check:focus + .btn, .btn:focus {
    box-shadow: none !important;
}

.rangeslider {
    margin: 15px 0 !important;
}

.modal-footer {
    border: none !important;
}

.modal-title {
    color: $active-color-blue;
}

.pointer {
    cursor: pointer;
}

.modal-dialog {
    @media (min-width: 577px) {
        max-width: 610px !important;
    }
}

.react-datepicker {
    min-width: 220px;
}

.react-datepicker-popper {
    z-index: 11 !important ;
}

.image-select {
    img {
        max-width: 22px !important;
    }
}

.ReactModal__Overlay {
    z-index: 1111 !important;
}

.car-detail-row {
    & > div, & > select {
        width: 100%;
        margin-right: 0px;

        
        @media (min-width: 768px) {
            width: 50%;
            padding-right: 10px;
            &:last-child {
                padding-left: 0;
            }
        }
        
        @media (min-width: 993px) {
            width: 25%;
            padding-right: 10px;
        }
    }
}

.full-width {
    @media (max-width: 992px) {
        width: 100% !important;
    }
}

.fuel-group {
    width: 100% !important;

    @media (min-width: 992px) {
        width: 50% !important;
    }
}

.customer-field .css-tsrali-control, .css-9x2jh-control {
    border: none !important;
    box-shadow: none !important;
}

.customer-field .css-1hb7zxy-IndicatorsContainer {
    display: none !important;
}

.error-text {
    font-size: 9px;
}

.drivable-group {
    & > div {
        width: 100%;

        &:last-child {
            width: 100%;
            .expires {
                width: 40%;
            }

            & > div {
                width: 40%;
            }
        }

        @media (min-width:1000px) {
            width: 50%;
    
            &:last-child {
                width: 100%;
            }
        }
        
        @media (min-width:1251px) {
            width: 25%;
            margin-bottom: 10px; 
    
            &:last-child {
                width: 50%;
            }
        }
    }
}

.overlay {
    backface-visibility: hidden;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: transparent;
    padding: 20px;
    color: #FFF;
    transform: scale(0);
    transform-origin: 0 0;
    transition: transform 400ms ease, opacity 200ms linear 200ms;
    z-index: 111;

    &.is-open {
      transform: scale(1);
    }
}

.modal-backdrop {
    z-index: -1111;
    &.show {
        z-index: 110;
    }
}

.modal-hiding {
    animation: modal-slide-bottom-animation-out .5s ease;
    -webkit-animation: modal-slide-bottom-animation-out .5s ease;
    -moz-animation: modal-slide-bottom-animation-out .5s ease;
    -o-animation: modal-slide-bottom-animation-out .5s ease;
}

.modal-opacity { 
    animation: modal-opacity-animation .5s ease;
    -webkit-animation: modal-opacity-animation .5s ease;
    -moz-animation: modal-opacity-animation .5s ease;
    -o-animation: modal-opacity-animation .5s ease;
}

.table_anchor {
    color: #246DCB !important;
    font-weight: bold;
}

.anticon {
    vertical-align: 0.05em !important;
}

.ant-table-tbody tr {
    cursor: pointer;
}

.ant-table-thead > tr > th, .ant-table-tbody > tr > td, .ant-table tfoot > tr > th, .ant-table tfoot > tr > td {
    padding: 16px 12px !important;
    font-size: 12px;
}

.custom-table-pos {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin-right: calc(var(--bs-gutter-x) * 0.5);
    margin-left: calc(var(--bs-gutter-x) * 0.5);
    background-color: #fff;
    z-index: 2;
}

.pointer, .reload-btn label {
    cursor: pointer;
}

.spin_loader {
    position: absolute !important;
    top: 50%;
    left: 50%;
    z-index: 222222;
}

.custom-detail-form .custom-form-label {
    left: 24px !important;
}

.slick-track {
    margin-left:0;
    transform: translate3d(0px, 0px, 0px) !important;
}

.slick-slider {
    z-index: 0;
}

.custom-form { 
    &-wrap {
        position: relative;

        input:focus + .custom-form-label {
            transform: translate(-10px, -25px);
            font-size: .9em;
            text-shadow: 1px 0 0 #fff, -1px 0 0 #fff, 2px 0 0 #fff, -2px 0 0 #fff, 0 1px 0 #fff, 0 -1px 0 #fff, 0 2px 0 #fff, 0 -2px 0 #fff;
        }
    }

    &-label {
        display: inline-block;
        position:absolute;
        left: 12px;
        top: 10px;
        transition: all 150ms ease-in;
        pointer-events: none;

        &.field-active {
            transform: translate(-10px, -25px);
            font-size: .8em;
            text-shadow: 1px 0 0 #fff, -1px 0 0 #fff, 2px 0 0 #fff, -2px 0 0 #fff, 0 1px 0 #fff, 0 -1px 0 #fff, 0 2px 0 #fff, 0 -2px 0 #fff;
        }
    }
}

div.select-box-bottom-line {
    div {
        padding: 0 !important;
        margin: 0 !important;
        border: none !important;
    
        span {
            margin: 0 !important;
        }
    }
}


.select-box-bottom-line > div:first-of-type {
    box-shadow: none !important;
   }

.imageDateInput input {
    width: 100% !important;
}

.insurance-detail .custom-form-label {
    left: 25px !important;
}

.indiana-scroll-container {
    overflow-y: hidden !important;
}

.action_bar {
    position: relative;
    flex: 0 0 25vw !important;
    max-width: 25vw !important;
    min-width: 25vw !important;
    width: 25vw !important;
    transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;

    &-collapsed {
        flex: 0 0 54px !important;
        max-width: 54px !important;
        min-width: 54px !important;
        width: 54px !important;
        background-color: #f1f4f9;
    }
}

.del-icon {
    color: rgba(0, 0, 0, 0.85);
}

.css-117b8wa-singleValue, .css-fj7p42-control {
    background-color: transparent !important;
}

.select-box-bottom-line .css-fj7p42-control {
    border-style: none !important;
    border-width: 1px !important;
}

.select-box-bottom-line .css-1wy0on6 {
    display: none !important;
}

.css-1nmuewe-control {
    border-width: 0 !important;
    border-style: none !important;
    box-shadow: none !important;
}

.ani-boxes > div {
    background: #f2f2f2;
    color: #fff;
    border-radius: 4px;
    padding: 1em;
    margin: 0 0 1em;
    width: 300px;

    h1::before {  
        transform: scaleX(0);
        transform-origin: bottom right;
      }
      
      h1:hover::before {
        transform: scaleX(1);
        transform-origin: bottom left;
      }
      
      h1::before {
        content: " ";
        display: block;
        position: absolute;
        top: 0; right: 0; bottom: 0; left: 0;
        inset: 0 0 0 0;
        background: hsl(200 100% 80%);
        z-index: -1;
        transition: transform .3s ease;
      }
      
      h1 {
        position: relative;
        font-size: 5rem;
      }
      
      html {
        block-size: 100%;
        inline-size: 100%;
      }
      
      body {
        min-block-size: 100%;
        min-inline-size: 100%;
        margin: 0;
        box-sizing: border-box;
        display: grid;
        place-content: center;
        font-family: system-ui, sans-serif;
      }
      
      @media (orientation: landscape) {
        body {
          grid-auto-flow: column;
        }
      }
  }
  .ani-boxes:nth-child(even) > div {
    background: #27b4a8;
  }
  
  .ani-boxes:nth-child(odd) > div {
    background: #f4645f;
  }
  

.note-board {
    &::before {
        content: '';
        border-top: 1px solid rgba(0, 0, 0, 0.4);
        width: 85%;
        right: 15px;
        position: absolute;

        @media (max-width: 1170px) {
            // width: 82%;
        }

        @media (max-width: 768px) {
            // width: 60%;
        }
    }

    span {
        color: $label-color-green;
        font-family: $sf-pro-display-regular !important;
        font-size: 12px;
        line-height: 18px;
    }
}

.ant-divider-horizontal {
    margin: 20px 0 !important;
}

.h-full {
    height: 100%;
}

.note-placeholder {
    overflow-y: auto;

    .is-read {
        td, span {
            font-family: $sf-pro-display-bold !important;
        }
    }

    .add-note-frame {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        padding: 30px 0px 40px;
        
        .comment-section {
            width: 100%;
            display: flex;
            justify-content: space-between;
            box-sizing: border-box;
            gap: 20px;
            flex-wrap: wrap;
        }

        button {
            border: none;
        }

        .comment-content {
            flex: 1;
            display: flex;
            flex-direction: column;
            gap: 10px;
            min-width: 450px;

            .attachment{
                height: 50px;
                width: 50px;
                object-fit: cover;
            }
        }

        .input-section {
            border-radius: 3px;
            background-color: $white;
            border: 1px solid $border-blue-light;
            box-sizing: border-box;
            display: flex;
            justify-content: space-between;
            flex-direction: row;
            align-items: center;
            padding: 10px 20px 10px 20px;
            gap: 10px;
            text-align: left;
            font-size: 0.875rem;
            color: $red-dark-gray;
            line-height: 100%;
            max-height: 40px;
        }
        
        .user-image {
            width: 22px;
            height: 22px;
            position: relative;
            border-radius: 116px;
            object-fit: cover;
        }

        .btn-save {
            border-radius: 3px 0px 0px 3px;
            background-color: $sky;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            padding: 11px 30px 11px 16px;
            box-sizing: border-box;
            text-align: left;
            font-size: 0.875rem;
            color: $white;  
            line-height: 100%;
            font-family: $sf-pro-display-medium !important;
            height: fit-content;
            cursor: pointer;
            .ant-spin-dot-item{
                background-color: $white;
            }
        }

        .btn-saved {
            background-color: $light-gray;
            border: 1px solid $border-blue-light;
            color: $sky;
            padding: 11px 16px;
        }

        .disable {
            background-color: $red-dark-gray !important;
        }
        
        .btn-arrow {
            position: relative;
            border-radius: 0px 3px 3px 0px;
            background-color: $sky;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            padding: 7px 16px 7px 0px;
            box-sizing: border-box;
            gap: 10px;
            height: fit-content;
            cursor: pointer;
        }
        .close-btn {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            padding: 8px 35px;
            box-sizing: border-box;
            height: fit-content;
            align-items: flex-start;
            margin-left: 10px;
            cursor: pointer;
        }

        @media (max-width: 768px) {
            .comment-section {
                gap: 10px;
            }
            .comment-content{
                min-width:fit-content;
            }
        }

        @media (max-width: 1210px) {
            .comment-section{
                gap: 10px;
            }
            .note-buttons {
                display: flex;
                width: 100%;
                justify-content: flex-end;
            }
            .close-btn{
                padding: 8px 25px;
            }
        }
    }

    .notes {
        display: flex;
        gap: 30px;
        flex-direction: column;
        .note-item {
            display: flex;
            gap: 14px;
            flex-direction: column;
            .sender-avatar {
                height: 22px;
                width: 22px;
                border-radius: 22px;
                object-fit: cover;
                padding: 0;
            }
            .name {
                font-size: 1.125rem;
                color: $bright-gray;
                line-height: 120%;
                font-family: $sf-pro-display-semibold !important;
            }
            .time {
                color: rgba($natural-blue, 0.6);
                font-size: 0.875rem;
                line-height: 120%;
            }
            .note-message {
                font-family: 'Roboto', sans-serif !important;
                p {
                    font-family: unset !important;
                    font-weight:  inherit !important;
                    margin-bottom: 0 !important;
                }
            }
            .note-attachement{
                height: 40px;
                width: 40px;
                object-fit: cover;
                border-radius: 2px;
                cursor: pointer;
            }
        }
    }

    .send-customer {
        position: relative;
        border-radius: 2px;
        background-color: $light-gray;
        border: 1px solid $border-blue-light !important;
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        padding: 11px 26px;
        text-align: left;
        font-size: 0.875rem;
        color: $natural-blue;
        margin-top: 5px;
        font-family: $sf-pro-display-medium !important;
        line-height: 100%;

        &:hover {
            background-color: $sky;
            color: $white;
        }
    }
}

.custom-notification {
    color: black;
}

.note-modal, .note-placeholder {

    .ant-modal-body {
        border-radius: 8px !important;
        box-shadow: 0px 2px 41px rgba(40, 41, 61, 0.23), 0px 4px 10px rgba(34, 34, 35, 0.35);
    }
    
    .ant-modal-content {
        border-radius: 8px !important;
    }

    .date {
        color: rgba(0, 0, 0, 0.6);
        font-size: 12px;
        font-family: $sf-pro-display-regular !important;
    }

    table {
        width: 100%;

        tr {
            border-bottom: 1px solid rgba(0, 0, 0, 0.2);  /* Move border-bottom to the correct place */

            td {
                padding: 8px 0;
                font-family: $sf-pro-display-regular !important;
                font-size: 12px;
                line-height: 22px;

                &:first-child {
                    color: #000000 !important;
                }
                &:nth-child(2) {
                    color: rgba(0, 0, 0, 0.7);
                }
                &:nth-child(3) {
                    color: rgba(93, 111, 136, 0.6);
                }
            }
        }
    }


    .alert-text {
        font-family: $sf-pro-display-semibold;
        font-size: 12px;
        line-height: 19px;
        color: rgba(0, 0, 0, 0.75);
    }
}

.radio_container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: #EBF4FF;
    width: 175px;
    height: 28px;
    border-radius: 9999px;

    input[type="radio"] {
        appearance: none;
        display: none;
    }
    
    label {
        font-family: $sf-pro-display-semibold !important;
        font-size: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #EBF4FF;;
        width: 87.5px;
        height: 28px;
        text-align: center;
        border-radius: 9999px;
        overflow: hidden;
        transition: linear 0.3s;
        color: #226BCB;
        margin: 0 !important;
        cursor: pointer;
    }
    
    input[type="radio"]:checked + label {
        background-color: #085ED0;;
        color: #ffffff;
        font-weight: 900;
        transition: 0.3s;
    }
    
    &.auto-alerts {
        width: 250px;
    }
}

.dot-alert {
    width: 5px;
    height: 5px;
    border-radius: 100%;
    background-color: 'red';
    margin-left: 5px;
}

.note-date-picker {
    .ant-picker {
        background-color: #EBF4FF;
        border: none !important;

        input:focus, select:focus {
            background-color: transparent !important;
        }
    }
}

.dashboard-panel {
    background: #fff;
    box-shadow: 0px 6px 7px rgba(40, 41, 61, 0.03), 0px 5px 5px -3px rgba(96, 97, 112, 0.02);
}

.w-fit{
    width: fit-content;
}

@keyframes twinkling {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

.twinkling-dot {
    margin-left: 5px;
    margin-bottom: 2px;
    animation: twinkling 2s infinite;
    height: 6px;
    width: 6px;
    background-color: red;
    border-radius: 50%;
}

@keyframes modal-slide-bottom-animation-out {
    from {
      top: 0;
      opacity: 1;
    }
    to {
      top: 100%;
      opacity: 0;
    }
}

@-webkit-keyframes modal-slide-bottom-animation-out {
    from {
      top: 0;
      opacity: 1;
    }
    to {
      top: 100%;
      opacity: 0;
    }
}
@-moz-keyframes modal-slide-bottom-animation-out {
    from {
      top: 0;
      opacity: 1;
    }
    to {
      top: 100%;
      opacity: 0;
    }
}

@keyframes modal-opacity-animation {
    0% {
        opacity: 1;
    }
    
      100% {
        opacity: 0;
    }
}

@-webkit-keyframes modal-opacity-animation {
    0% {
        opacity: 1;
    }
    
      100% {
        opacity: 0;
    }
}

@-moz-keyframes modal-opacity-animation {
    0% {
        opacity: 1;
    }
    
      100% {
        opacity: 0;
    }
}

@-o-keyframes modal-opacity-animation {
    0% {
        opacity: 1;
    }
    
      100% {
        opacity: 0;
    }
}

@keyframes modal-slide-bottom-animation {
    from {
      top: 100%;
    }
    to {
      top: 50%;
    }
}

@-webkit-keyframes modal-slide-bottom-animation {
    from {
      top: 100%;
    }
    to {
      top: 50%;
    }
}

@-moz-keyframes modal-slide-bottom-animation {
    from {
      top: 100%;
    }
    to {
      top: 50%;
    }
}

@-o-keyframes modal-slide-bottom-animation {
    from {
      top: 100%;
    }
    to {
      top: 50%;
    }
}

@keyframes pulseGlow {
    0%, 100% {
      box-shadow: 0 0 10px 0 rgba(0, 123, 255, 0.7);
    }
    16.67%, 83.33% {
      box-shadow: 0 0 20px 10px rgba(0, 123, 255, 0.7);
    }
}


.public-DraftEditor-content {
    min-height: 150px;
    padding: 10px;
}

.draft-editor-container {
    flex: 1;
    border-radius: 3px;
    background-color: $white;
    border: 1px solid $border-blue-light;
    gap: 10px;
    min-height: 150px;
    min-width: 450px;
    font-size: 1rem;
}

.bc4rxid {
    background-color: $white;
    padding-top: 0px;
    color: $meteorite-gray;
    font-size: 1rem;
    svg {
        height: 20px;
        width: 20px;
        fill: $meteorite-gray;
    }
}

.akzb7t5 {
    background-color: $weathered;
    color: $sky;
    border-radius: 3px;
    color: $sky;
    svg {
        fill: $sky;
    }
}

.public-DraftStyleDefault-block {
    span {
        font-family: 'Roboto', sans-serif !important;
        h1, h2, h3, h4, h5, h6, div, span, p {
            font-family: unset !important;
            font-weight:  inherit !important;
        }
    }
}


.public-DraftEditor-content {
    min-height: 150px;
    padding: 10px;
}

.draft-editor-container {
    flex: 1;
    border-radius: 3px;
    background-color: $white;
    border: 1px solid $border-blue-light;
    gap: 10px;
    min-height: 150px;
    min-width: 450px;
    font-size: 1rem;
}

.bc4rxid {
    background-color: $white;
    padding-top: 0px;
    color: $meteorite-gray;
    font-size: 1rem;
    svg {
        height: 20px;
        width: 20px;
        fill: $meteorite-gray;
    }
}

.akzb7t5 {
    background-color: $weathered;
    color: $sky;
    border-radius: 3px;
    color: $sky;
    svg {
        fill: $sky;
    }
}

.public-DraftStyleDefault-block {
    span {
        font-family: 'Roboto', sans-serif !important;
        h1, h2, h3, h4, h5, h6, div, span, p {
            font-family: unset !important;
            font-weight:  inherit !important;
        }
    }
}
.cursor-no-allowed {
    cursor: not-allowed;
}

.sidebar-main .mobile_open {
    svg {
        height: 24px;
        width: 24px;
    }
}
.hel-top-navbar {
    padding-left: 0;
}

.contents {
    display: contents;
}