@use '../helpers/mixins' as *;
@use '../helpers/variables' as *;
@use '../helpers/fonts' as *;

body {
    .tool-title {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #01459d;
    }

    .dashed {
        @include borderLine(1px, dashed, $border-blue);
    }

    .gap-content {
        height: 250px;
        width: 100%;
        margin: auto;
        text-align: center;
        border-radius: 2px;
    }

    .right-tool-box {
        height: 35px;
        width: 100%;
        border-radius: 2px;
        @include borderLine(0.5px, dashed, #000);
        background-color: $gray-bg;
    }
    
    .right-tool-bottom-box {
        width: 100%;
        border-radius: 2px;
        @include borderLine(0.5px, dashed, #000);
        background-color: $gray-bg;
    }

    input, select {
        &:focus {
            background-color: $blue-focus-bg !important;
        }
    }

    .job {
        &-content {
            background: #fff;
            box-shadow: $box-shadow-retangle;
        }

        &-type {
            &-group {
                label  {
                    font-size: 13px;
                    padding: 7px 15px;
                    border: 1px solid rgba(8, 94, 208, 0.4);
                    color: $primary !important;
                    border-radius: 76px;
                    transition: all 0.3s ease-out 0s;

                    img {
                        width: 11px;
                        display: none;
                        opacity: 0;
                    }
                }

                .btn-check:checked + .btn {
                    background-color: $blue-focus-bg;
                    border: none !important;
                }

                .btn-check:checked  + .btn img {
                    opacity: 1;
                    display: initial;
                }
            }

            .btn-check:focus + .btn, .btn:focus {
                box-shadow: none !important;
            }

            .or-divider {
                text-align: center;
                line-height: 3;
            }

            .add-client {
                background-color: #EBF4FF;
                filter: drop-shadow(0px 0px 1px rgba(40, 41, 61, 0.04)) drop-shadow(0px 2px 4px rgba(96, 97, 112, 0.16));
            }

        }

    }

    .cardetail-select {
        background-color: $blue-focus-bg !important;
        border: 1px solid #27B264 !important;
    }

    .image-select {
        .css-1okebmr-indicatorSeparator {
            background-color: white;
        }

        .css-9x2jh-control {
            border: none !important;
        }

        #react-select-3-input {
            &:focus {
                .image-select span {
                    background-color: $blue-focus-bg;
                }
            }
        }

        div {
            margin: 0 !important;
        }
    }

    .color-group {
        & > div {
            width: 20%;
            @media (max-width: 990px) {
                width: 50%;
            }
        }
    }

    .mileage-group {
        span {
            color: #000;
            font-size: 13px;
            line-height: 3;
        }
    }

    .custom-select {
        width: 113px;
        height: 29px;
        background: #FFFFFF;
        border: 1px solid rgba(0, 0, 0, 0.15);
        box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.3);
        border-radius: 2px;
        background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2212%22%20height%3D%2212%22%20viewBox%3D%220%200%2012%2012%22%3E%3Ctitle%3Edown-arrow%3C%2Ftitle%3E%3Cg%20fill%3D%22%23000000%22%3E%3Cpath%20d%3D%22M10.293%2C3.293%2C6%2C7.586%2C1.707%2C3.293A1%2C1%2C0%2C0%2C0%2C.293%2C4.707l5%2C5a1%2C1%2C0%2C0%2C0%2C1.414%2C0l5-5a1%2C1%2C0%2C1%2C0-1.414-1.414Z%22%20fill%3D%22%23000000%22%3E%3C%2Fpath%3E%3C%2Fg%3E%3C%2Fsvg%3E");
        background-size: .6em;
        background-position: calc(100% - 0.8em) center;
        background-repeat: no-repeat;
    }

    .switch-wrap {
        @media (max-width: 768px) {
            width: 100%;
        }
    }

    .ant-table-wrapper {
        @media (max-width: 768px) {
            width: 100%;
            overflow-x: scroll;
        }
    }

    .ant-table-tbody {
        tr {
            background-color: #ffffff;
        }
    }

    .note_selected_image {
        width: 40px;
        height: 40px;
        position: relative;

        img {
            width: 100%;
            height: 100%;
        }

        .image-container {
            width: 100%;
            border-radius: 4px;
            padding-top: 100%;
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
        }

        .remove {
            width: 12px;
            height: 12px;
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            right: -6px;
            top: -4px;
            padding-left: 4.5px;
            cursor: pointer;
            font-size: 9px;
            padding: 3px;
            border-radius: 10px;
            background: #FFF;
            box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.30);
        }
    }

    .note-add_more {
        font-family: 'Roboto', sans-serif;
        color: #01459D;
        font-weight: 800;
        font-size: 12px;
        font-style: normal;
        line-height: 21px;
        cursor: pointer;
    }
}

.anti-tooltip-open {
    cursor: pointer;
}

.profile_temp-title {
    border-bottom: 1px solid rgb(199, 211, 230);
}

.job-main-column {
    border-right: 1px solid $border-blue-light;
    background: $white;

    .job-inner-column {
        background: $white;
    }
}

#content-page {
    transition: width .3s ease-in-out, margin-left .3s ease-in-out !important;
}

.note_modal {
    width: 100% !important;
    max-width: 550px !important;
    background-color: transparent;

    .ant-modal-body {
        padding: 0 !important;
    }

    .ant-modal-close {
        display: none;
    }

    .ant-modal-content {
        background: transparent !important;
    }

    .note_wrap {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        .note_header {
            padding: 10px 20px;
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            border-radius: 3px 3px 0px 0px;
            border: 1px solid #F5F5F5;
            background: #F6FAFF;

            * {
                color: #5D6F88;
                font-size: 9px;
                font-style: normal;
                font-weight: 700;
                line-height: 120%;
                letter-spacing: 0.5px;
                text-transform: uppercase;
            }
        }

        .note_content {
            min-height: 228px;
            width: 100%;
            background-color: #fff;
            padding: 20px;
        }

        .note_header > span, .note_content > span {
            text-align: left;
            display: inline-block;

            &:first-child {
                width: 15%;
            }

            &:nth-child(2) {
                width: 15%;
            }

            &:nth-child(3) {
                width: 50%;
            }

            &:nth-child(4) {
                width: 20%;
            }
        }

        .note_footer {
            display: flex;
            width: 100%;
            padding: 10px 20px;
            align-items: flex-start;
            justify-content: space-between;
            border-radius: 0px 0px 3px 3px;
            border-right: 1px solid #F5F5F5;
            border-bottom: 1px solid #F5F5F5;
            border-left: 1px solid #F5F5F5;
            background: #F6FAFF;

            & > div {
                width: 50%;
                display: flex;
                width: 100%;
                align-items: center;
                &:first-child {
                    justify-content: space-between;
                    padding-right: 2.5rem;
                }

                &:last-child {
                    justify-content: flex-end;
                }
            }

            .note_previus {
                display: flex;
                flex-direction: row;
                align-items: center;
                cursor: pointer;

                svg {
                    margin-right: 8px;
                }

                span {
                    font-size: 10px;
                    font-style: normal;
                    font-weight: 510;
                    line-height: 21px; /* 210% */
                    color: rgba(0, 81, 186, 0.70);

                    &.disabled {
                        color: #EAEAEA;
                    }
                }
            }

            .note_reply {
                cursor: pointer;
                svg {
                    margin-right: 8px;
                }

                span {
                    color: #5D6F88;
                    font-size: 10px;
                    font-style: normal;
                    font-weight: 510;
                    line-height: 21px; /* 210% */
                }
            }

            .note_next-message {
                cursor: pointer;
                svg {
                    margin-left: 8px;
                }

                span {
                    color: rgba(0, 81, 186, 0.70);
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 510;
                    line-height: 21px; /* 150% */

                    &.disabled {
                        color: #EAEAEA;
                    }
                }
            }
        }
    }
}

.photomanagement-photos {
    width: 100%;
}

.custom-slider .slick-list {
    overflow-x: auto;
    padding-bottom: 18px;

    @media (max-width: 768px) {
        padding-bottom: 20px;
    }
}
  
.slider-item {
    padding: 0 5px; /* Adjust the value as needed */
    height: 100px;
    cursor: pointer;
    // width: 100px !important;
    .add-img{
        object-fit: fill;
    }
}

@media (max-width:480px) {
    
    .slick-track{
        // width: 100% !important;
        .slick-slide {
            height: 300px !important;
            // width: 33.33% !important;
        }
    }

}

.slick-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 2px;
}

.add_icon {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    gap: 10px;
    padding: 14px 0 0 0px;
    border-top: 1px solid $border-blue-light;

    span {
        color: $bright-gray !important;
        font-size: 0.875rem;
        font-style: normal;
        line-height: 18px;
    }

    svg {
        cursor: pointer;
    }
}

.photomanagement-button-add {
    padding: 6.5px 15px;
    background-color: $white;
    box-sizing: border-box;
    border-radius: 2px;
    line-height: 21px;
    font-weight: 500;
    white-space: pre-wrap;
    font-size: 0.75rem;
    color: $sky;
    cursor: pointer;
    line-height: 21px;
    span {
        font-family: $sf-pro-display-medium !important;
    }
}

.photomanagement-button-clear {
    padding: 6.5px 20px;
    border-radius: 2px;
    box-sizing: border-box;
    font-size: 0.75rem;
    color: $natural-blue;
    line-height: 21px;
    box-sizing: border-box;
    background-color: $white;
    color: $sky;
    line-height: 21px;
    white-space: pre-wrap;
    cursor: pointer;
    span {
        font-family: $sf-pro-display-medium !important;
    }
}

.photomanagement {
    width: 48%;

    @media (max-width: 1250px) {
        order: 3;
        width: 100%;
        border-bottom: 1px solid $border-blue-light;
        padding-bottom: 15px;
    }

    .ant-spin-nested-loading {
        height: 100%;
    }

    .ant-spin-container {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .section-photo-label {
        padding: 14px 0 12px;

        b {
            letter-spacing: 0.5px;
            line-height: 100%;
            text-transform: uppercase;
            color: $natural-blue;
            opacity: 0.7;
            font-size: 1rem;
            font-family: $sf-pro-display-medium;
        }
    }
}

.photomanagement {

    .block-header {
        padding: 14px 0 12px;
        cursor: default;
        pointer-events: none;
        svg {
            display: none;
        }
    }

    @media(max-width: 780px) {
        .block {
            cursor: pointer;
            pointer-events: all;
        }

        .block-header {
            svg {
                display: flex;
            }
        }
    }
}

.files-footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 10px;
    background-color: $sky;
    border-radius: 3px;
    padding: 10px 10px 10px 15px;
    box-sizing: border-box;
    gap: 22px;
    align-items: center;
    transition: opacity 0.3s ease-in-out;
    opacity: 0;
    visibility: hidden;

    .selected {
        font-size: 0.75rem;
        line-height: 120%;
        color:$white !important;
        font-family: $sf-pro-display-regular !important;
        white-space: nowrap;
    }

    .footer-btns{
        display: flex;
        gap: 10px;
        align-items: center;
        flex-wrap: wrap;
        
        .btn-icon{
            border-radius: 3px;
            background-color: $white;
            border: 1px solid $white;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            width: 36px;
            height: 34px;
            span {
                svg {
                    fill: $sky;
                }
            }
        }
    }

    .file-btns {
        display: contents;
    }

    @media(max-width: 480px) {
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;

        .file-btns {
            flex-direction: row;
            justify-content: flex-start;
            display: flex;
            align-items: center;
            gap: 10px;

            button {
                margin: 0 !important;
            }
        }
    }
}

.files-footer.visible {
    opacity: 1;
    visibility: visible;
}

.files-footer.fade-out {
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out 0.3s;
}

.filter_wrap {
    label {
        color: $label-color-green;
        margin-bottom: 0;
        font-size: 0.625rem;
        letter-spacing: 0.3px;
        line-height: 16px;
        font-weight: 600;
    }

    @media(max-width: 600px) {
        width: 100%;
        justify-content: space-between;
    }
}

@media (max-width: 767px) {
    .content-page {
        padding: 100px 0 0 0 !important;
    }
}

@media (max-width: 500px) {
    .half-basis {
        flex-basis: 48%;
    }
}